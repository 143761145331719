<template>
  <div class="card-body">
    <div class="row mb-3">
      <h3 class="mb-0 col-md-6">My Observations</h3>
      <div class="input-group col-md-6">
        <b-input-group-prepend>
                <span
                  class="btn btn-secondary"
                  title="Search by location"
                >
                  <i v-if="!isBusy" class="fas fa-search"></i>
                  <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
                </span>
        </b-input-group-prepend>
        <b-form-input
          placeholder="Search by Location Name"
          name="search"
          type="search"
          v-model="searchText"
          debounce="1000"
        ></b-form-input>
        <div class="input-group-append" v-if="searchText">
          <b-button @click="searchText = ''"
          ><i class="fas fa-times-circle"></i
          ></b-button>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="my-1 col-md-4">
        Showing {{ fromItem }} to {{ toItem }} of
        {{ totalItems }}
        observation<span v-if="totalItems === 0 || totalItems > 1">s</span>
      </div>

      <div class="col-md-3 my-1">
        <b-pagination
          v-if="totalItems > 0"
          :total-rows="totalItems"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0 pagination-holder"
          prev-text="Prev"
          next-text="Next"
          align="fill"
        />
      </div>

      <div class="col-md-3 my-1">
        <b-form-group
          label="Per Page"
          label-align="right"
          label-cols-sm="7"
          label-cols-md="8"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            align="right"
          >
          </b-form-select>
        </b-form-group>
      </div>
    </div>

    <b-table
      show-empty
      stacked="md"
      id="observationsTable"
      ref="observationsTable"
      :filter="filterVal"
      :busy="isBusy"
      :items="observations"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(options)="row">
        <b-btn
          class="mt-1 mr-1"
          :to="{ name: 'ObservationShow', params: { id: row.item.id } }"
          alt="View Observation"
          title="View Observation"
          target="_blank"
          noopener noreferrer
        >
          <font-awesome-icon icon="eye"/>
          View
        </b-btn>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Observations..."/>
      </template>
    </b-table>

    <div class="card-footer">
      <div class="row justify-content-between">
        <div class="my-1 col-md-4">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }}
          observation<span v-if="totalItems === 0 || totalItems > 1">s</span>
        </div>

        <div class="col-md-3 my-1">
          <b-pagination
            v-if="totalItems > 0"
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </div>

        <div class="col-md-3 my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader";

export default {
  name: "UserObservationsTable",
  components: {
    VueLoader
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,

      tableFields: [
        {
          key: "observedAt",
          label: "Date Observed",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "createdAt",
          label: "Date Created",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "project.name",
          label: "Project",
          sortable: true,
          tdClass: "text-dark",
          formatter: val => {
            return val;
          }
        },
        {
          key: "location.name",
          label: "Location name",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: (val, fieldName, item) => {
            return item && item.location && item.location.name;
          }
        },
        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'observation/user/isLoading',
      observationPage: 'observation/user/observationPage',
      observationItems: 'observation/user/observationItems',
      observationTotal: 'observation/user/observationTotal',
      observationSearch: 'observation/user/observationSearch',
      observationSortBy: 'observation/user/observationSortBy',
      observationSortDirection: 'observation/user/observationSortDirection',
      observationSortDesc: 'observation/user/observationSortDesc',
    }),
    currentPage: {
      get() {
        return this.observationPage
      },
      set(newValue) {
        this.setObservationTablePage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.observationTotal
      },
      set(newValue) {
        this.setObservationTableTotal(newValue)
      }
    },
    perPage: {
      get() {
        return this.observationItems
      },
      set(newValue) {
        this.setObservationTableItems(newValue)
      }
    },
    searchText: {
      get() {
        return this.observationSearch
      },
      set(newValue) {
        this.setObservationTableSearch(newValue)
      }
    },
    sortBy: {
      get() {
        return this.observationSortBy
      },
      set(newValue) {
        this.setObservationSortBy(newValue)
      }
    },
    sortDirection: {
      get() {
        return this.observationSortDirection
      },
      set(newValue) {
        this.setObservationSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.observationSortDesc
      },
      set(newValue) {
        this.setObservationSortDesc(newValue)
      }
    },

    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search observations by location name
        //More filters can be added here
        // - also add an equivalent to the store

        let retVal = ''
        if (this.searchText) {
          retVal = retVal.length === 0 ? 'location.name=' + this.searchText : retVal + '&location.name=' + this.searchText
        }
        return retVal
      }
    },
  },
  methods: {
    ...mapActions({
      fetchObservations: 'observation/user/get',
      setObservationTablePage: 'observation/user/setObservationTablePage',
      setObservationTableItems: 'observation/user/setObservationTableItems',
      setObservationTableTotal: 'observation/user/setObservationTableTotal',
      setObservationTableSearch: 'observation/user/setObservationTableSearch',
      setObservationSortBy: 'observation/user/setObservationTableSortBy',
      setObservationSortDirection: 'observation/user/setObservationTableSortDirection',
      setObservationSortDesc: 'observation/user/setObservationTableSortDesc',
    }),
    observations(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {id: this.projectId, urlParams};
      return this.fetchObservations(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(observation => {
          console.log(observation)
          return {
            ...observation
          };
        });
      });
    },
  }
};
</script>

<style scoped lang="scss">

</style>
