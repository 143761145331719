<template>
  <div class="mt-2 ml-2 mr-2">
    <div v-if="isManager">
      <b-row>
        <b-col class="col">
          <b-card class="mb-2" border-variant="light" bg-variant="light">
            <p>Welcome to the Airtable Integration!<br>
              You can connect your CitSci project with <a href="https://airtable.com" target="_blank" rel="noopener noreferrer">Airtable</a>
              <span><i class="fas fa-external-link-alt fa-xs"></i></span>
              <br>This will facilitate sharing of CitSci data to your Airtable bases.</p>
            <br>
            <h6>Read more about this integration
              <a href="https://support.airtable.com/docs/third-party-integrations-via-oauth-overview" target="_blank" rel="noopener noreferrer">here</a>
              <span><i class="fas fa-external-link-alt fa-xs"></i></span>
            </h6>
          </b-card>
        </b-col>
      </b-row>
      <div v-if="projectVuex.isAirtable">
        <AuthorizationStatus></AuthorizationStatus>
        <BaseSelection
            v-if="userSession && (userSession['isAirtable'] && !userSession['isAirtableExpired'])"></BaseSelection>
        <History :userProfile="false"></History>
      </div>
      <div v-else>
        <b-card class="col-6 mb-2" border-variant="light" bg-variant="light">
          Airtable Integration has not been Enabled.<br>
          Enable it under Edit Project.
        </b-card>
      </div>
    </div>
    <RequiresManager v-else page="Airtable Integration"></RequiresManager>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {airTableOAuth} from "../../../../main";
import AuthorizationStatus from "./authorizationStatus.vue";
import BaseSelection from "./baseSelection.vue";
import History from "./history.vue"
import RequiresManager from "../tabpermission/requiresManager.vue";

export default {
  components: {
    RequiresManager,
    AuthorizationStatus,
    BaseSelection,
    History,
  },
  data() {
    return {      collapse: true,
      selectedBase: null,
      toggleTableType: null,
      newTableName: '',
      selectedTable: null,
    };
  },

  created() {
    if (this.userSession && (this.userSession['isAirtable'] && !this.userSession['isAirtableExpired'])) {
      // Bases are fetched
      this.getBases()
    }
  },

  watch: {
    toggleTableType(newValue) {
      if (newValue && newValue === 'existing') {
        this.getTables(this.selectedBase)
      }
    }
  },

  computed: {
    ...mapGetters({
      userSession: 'user/profile/sessionData',
      isManager: "project/get/isManager",
      projectVuex: 'project/get/project',
      isLoadingBases: "integration_airtable/bases/isLoadingBases",
      bases: "integration_airtable/bases/bases",
      basesError: "integration_airtable/bases/basesError",
      isLoadingTables: "integration_airtable/bases/isLoadingTables",
      tables: "integration_airtable/bases/tables",
      tablesError: "integration_airtable/bases/tablesError"
    }),
    getAirtableBases() {
      if (this.bases) {
        let arrBases = []
        arrBases.push({value: null, text: "Pick a Base...", disabled: true})
        Object.values(this.bases).forEach(b => {
          arrBases.push({value: b.id, text: b.name})
        })
        return arrBases
      } else {
        return []
      }
    },
    getAirtableTables() {
      if (this.tables) {
        let arrTables = []
        arrTables.push({value: null, text: "Pick a Table...", disabled: true})
        Object.values(this.tables).forEach(b => {
          arrTables.push({value: b.id, text: b.name})
        })
        return arrTables
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions({
      saveProjectId: 'integration_airtable/authorize/saveProjectId',
      getBases: "integration_airtable/bases/get",
      getTables: "integration_airtable/bases/getTables",
    }),

    // Handle authorize with airtable
    async onClickAuthorizeAirtable() {
      // Current project id is saved to local storage
      //  - this facilitates returning the user to the project after authorizing with airtable
      this.saveProjectId()
      // Set the data param that will be round tripped
      let randData = window.crypto.getRandomValues(new Uint32Array(28));
      airTableOAuth.createSigninRequest({state: {bar: randData}})
          .then(function (req) {
            window.location = req.url;
          })
          .catch(function (err) {
            console.log(err);
          });
    }
  }
}
</script>

<style lang="scss">
</style>
