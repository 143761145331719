import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    userAssignedLocations: null,
    error: null,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  userAssignedLocations(state) {
    return state.userAssignedLocations
  },
  error(state) {
    return state.error
  },
}

const actions = {
  get({commit, state, rootGetters}, urlParams) {
    commit(types.RESET)
    commit(types.SET_ASSIGNED_LOCATIONS_LOADING)
    let userId = rootGetters["user/profile/id"]
    return fetch('/users/' + userId + '/assigned_locations' + urlParams)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_ASSIGNED_LOCATIONS, data['hydra:member'])
        return data
      })
      .catch((error) => {
        commit(types.SET_ASSIGNED_LOCATIONS_ERROR, error)
      })
  }
}

const types = {
  SET_ASSIGNED_LOCATIONS_LOADING: 'SET_ASSIGNED_LOCATIONS_LOADING',
  SET_ASSIGNED_LOCATIONS: 'SET_ASSIGNED_LOCATIONS',
  SET_ASSIGNED_LOCATIONS_ERROR: 'SET_ASSIGNED_LOCATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_ASSIGNED_LOCATIONS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_ASSIGNED_LOCATIONS](state, observations) {
    state.userAssignedLocations = observations
    state.isLoading = false
  },
  [types.SET_ASSIGNED_LOCATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
