import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import {urlBuilder} from "../../../../helpers/url_builder_helper";


/**
 * @function retrieve - retrieve from API project data
 * @param commit
 * @param {String} id project ID
 * @returns {Promise<T | never> | * | *}
 */
export const retrieve = ({ commit }, id) => {
  commit(types.PROJECT_SHOW_TOGGLE_LOADING)
  commit(types.GET_PROJECT_ID_BY_PROJECT_NAME_ERROR, '')

  return fetch('/projects/' + id)
    .then(response => response.json())
    .then((data) => {
      commit(types.PROJECT_SHOW_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.PROJECT_SHOW_TOGGLE_LOADING)
      commit(types.GET_PROJECT_ID_BY_PROJECT_NAME_ERROR, e.message)
    })
}

/**
 * @function getProjectIdByName - Get Project ID from Project Name
 * @param commit
 * @param {String} id project Name
 * @returns {Promise<T | never> | * | *}
 */
export const getProjectIdByName = ({ commit }, name) => {
  commit(types.GET_PROJECT_ID_BY_PROJECT_NAME_TOGGLE_LOADING);
  commit(types.PROJECT_SHOW_SET_ERROR, '')

  return fetch('/projects/' + name + '/id')
    .then(response => response.json())
    .then((data) => {
      commit(types.GET_PROJECT_ID_BY_PROJECT_NAME_TOGGLE_LOADING);
      commit(types.GET_PROJECT_ID_BY_PROJECT_NAME, data);
      if (data !== undefined) {
        localStorage.setItem('ClickedProjectId', data.id);
      }

      return data;
    })
    .catch((e) => {
      commit(types.GET_PROJECT_ID_BY_PROJECT_NAME_TOGGLE_LOADING);
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    });
};

/**
 * @function getProjectIdByNameWithoutCommit - Get Project ID from Project Name
 * @param commit
 * @param {String} id project Name
 * @returns {Promise<T | never> | * | *}
 */
export const getProjectIdByNameWithoutCommit = (name) => {

  return fetch('/projects/' + name + '/id')
    .then(response => response.json())
    .then((data) => {
      if (data !== undefined) {
        window.ClickedProjectId = data.id;
        return true;
      }
      return false;
    })
    .catch((e) => {
      return false
    });
  return false;
};

/**
 * @function getProjectLocations - get locations for the project
 * @param commit
 * @param {Object} data object with projectId and url params for pagination
 * @returns {Promise<T | never> | * | *}
 */
export const getProjectLocations = ({ commit }, data) => {
  commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')
  let id = data.id
  const urlParams = data.urlParams ? data.urlParams : ""

  return fetch('/projects/' + id + '/locations' + urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
      commit(types.SET_PROJECT_LOCATIONS, data['hydra:member'])
      return data
    })
    .catch((e) => {
      commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

function fetchProjectLocationsBlock({commit}, {url, ctx}) {
  commit(types.GET_ALL_PROJECT_LOCATIONS_TOGGLE_LOADING)
  return fetch(url + `${urlBuilder(ctx)}`)
    .then(response => response.json())
    .then((data) => {
      commit(types.GET_ALL_PROJECT_LOCATIONS_TOGGLE_LOADING)
      commit(types.SET_ALL_PROJECT_LOCATIONS, data['hydra:member'])
      if (ctx.currentPage === 1) return data
    })
    .catch((e) => {
      commit(types.GET_ALL_PROJECT_LOCATIONS_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

export const getAllProjectLocations = ({ commit }, data) => {
  commit(types.PROJECT_SHOW_SET_ERROR, '')
  commit(types.RESET_ALL_PROJECT_LOCATIONS)
  const maxFetch = 200;
  const ctx = {currentPage: 1, perPage: maxFetch, sortDesc: true, sortBy: 'observationsTotal'};
  let url = '/projects/' + data.id + '/locations'
  let totalItems = null;
  fetchProjectLocationsBlock({commit}, {url, ctx})
    .then(async (data) => {
      totalItems = data['hydra:totalItems'];
      if (totalItems > maxFetch) {
        for (let i = 1; i < (totalItems / maxFetch); i++) {
          const ctx = {currentPage: i + 1, perPage: maxFetch, sortDesc: true, sortBy: 'observationsTotal'};
          await fetchProjectLocationsBlock({commit}, {url, ctx})
        }
      }
  });
}

/**
 * @function removeProjectLocation - remove locations for the project
 * @param commit
 * @param {Object} data object with projectId and url params for pagination
 * @returns {Promise<T | never> | * | *}
 */
export const removeProjectLocation = ({ commit }, data) => {
  commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')
  let id = data.id

  return fetch('/areas/' + id, { method: 'DELETE' })
    .then(response => response.status === 204)
    .then((data) => {
      commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
      commit(types.SET_PROJECT_LOCATIONS, data)
    })
    .catch((e) => {
      commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}


export const checkProjectLocationExists = ({ commit }, data) => {

  commit(types.PROJECT_CHECK_LOCATION_EXISTS_TOGGLE_LOADING)
  commit(types.PROJECT_CHECK_LOCATION_EXISTS_SET_ERROR, '')

  // ProjectId is retrieved
  let id = window.ClickedProjectId

  return fetch('/projects/' + id + '/locations?name[searchAny]=' + data.name + '&latitude=' + data.latitude + '&longitude=' + data.longitude)
    .then(response => response.json())
    .then(data => {
      commit(types.PROJECT_CHECK_LOCATION_EXISTS_TOGGLE_LOADING)
      commit(types.PROJECT_CHECK_LOCATION_EXISTS_SET_RETRIEVED, data['hydra:member'])
      return data['hydra:member']
    })
    .catch((e) => {
      commit(types.PROJECT_CHECK_LOCATION_EXISTS_TOGGLE_LOADING)
      commit(types.PROJECT_CHECK_LOCATION_EXISTS_SET_ERROR, e.message)
    })
}


/**
 * @function retrieved set object state with retrieved project
 * @param commit
 * @param state
 * @param response
 */
export const retrieved = ({ commit, state }, response) => {
  commit(types.PROJECT_SHOW_SET_RETRIEVED, response)
}

/**
 * @function reset the state of the object to default values
 * @param commit
 */
export const reset = ({ commit }) => {
  commit(types.PROJECT_SHOW_RESET)
}

/**
 * @function createDatasheet - create a new datasheet for the project
 * @param commit
 * @param {Object} values object with the data that datasheet should contain at the beginning
 * @returns {Promise<T | never> | * | *}
 */
export const createDatasheet = ({ commit }, values) => {
  commit(types.DATASHEET_CREATE_SET_ERROR, '')
  commit(types.DATASHEET_CREATE_TOGGLE_LOADING)

  return fetch('/datasheets', { method: 'POST', body: JSON.stringify(values) })
    .then((response) => {
      commit(types.DATASHEET_CREATE_TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.DATASHEET_CREATE_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.DATASHEET_CREATE_SET_ERROR, e.message)
    })
}

/**
 * @function resetDatasheet Reset Datasheet object to the default values
 * @param commit
 */
export const resetDatasheet = ({ commit }) => {
  commit(types.DATASHEET_CREATE_RESET)
}

/**
 * @function setSearchTerm - set in state object the search value string
 * @param commit
 * @param val
 */
export const setSearchTerm = ({ commit }, val) => {
  commit(types.SET_SEARCH_TERM, val)
}

/**
 * @function joinToProject - join user as member on a project
 * @param commit
 * @param _data
 * @param {String} projectId the Project ID that the user should be added as member
 * @returns {Promise<T | never> | * | *}
 */
export const joinToProject = ({ commit, dispatch }, _data) => {
  commit(types.JOIN_MEMBER_LOADING)

  let projectId = _data.id
  let data = _data.data || null
  let options = { method: 'POST' }

  if (data) {
    options.body = JSON.stringify(data)
  } else {
    options.body = JSON.stringify({})
  }

  return fetch(('/projects/' + projectId + '/join'), options)
    .then((response) => {
      commit(types.JOIN_MEMBER_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.JOIN_MEMBER_DONE_CREATED, data)
      dispatch('user/profile/fetchSession', null, { root: true }).then(() => {
        return
      })
    })
    .catch((e) => {
      commit(types.JOIN_MEMBER_LOADING)
      dispatch('user/profile/fetchSession', null, { root: true }).then(() => {
        return
      })
      if (e instanceof SubmissionError) {
        commit(types.JOIN_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.JOIN_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.JOIN_MEMBER_SET_ERROR, e.message)

    })
}

/**
 * @function approveProjectMember - approve a member into the project
 * @param commit
 * @param {Object} data object with the User ID and Project ID (User ID that should be approved into the Project ID as member)
 * @returns {Promise<T | never> | * | *}
 */
export const approveProjectMember = ({ commit }, data) => {
  commit(types.APPROVE_MEMBER_LOADING)

  return fetch('/projects/' + data.projectId + '/members/' + data.userData.id, {
    method: 'PATCH',
    body: JSON.stringify({ approved: true })
  })
    .then((response) => {
      commit(types.APPROVE_MEMBER_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.APPROVE_MEMBER_DONE, data)
      return data
    })
    .catch((e) => {
      commit(types.APPROVE_MEMBER_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.APPROVE_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.APPROVE_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.APPROVE_MEMBER_SET_ERROR, e.message)
    })

}

/**
 * @function deleteProjectMember - delete a member from the project
 * @param commit
 * @param {object} data object with project ID and project_member.id (user ID that should be removed from Project ID)
 * @returns {Promise<T | never> | * | *}
 */
export const deleteProjectMember = ({ commit }, data) => {
  commit(types.DELETE_MEMBER_LOADING)

  return fetch(('/projects/' + data.projectId + '/members/' + data.userData.id), { method: 'DELETE' })
    .then((response) => {
      commit(types.DELETE_MEMBER_LOADING)
      if (response.status !== 204)
        return response.json()
    })
    .then((data) => {
      commit(types.DELETE_MEMBER_DONE, data)
    })
    .catch((e) => {
      commit(types.DELETE_MEMBER_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DELETE_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DELETE_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.DELETE_MEMBER_SET_ERROR, e.message)
    })

}

/**
 * @function getMembersList - Get paginated project members list
 * @param commit
 * @param {object} data object that contains projectId and urlParams property
 *      generated by urlBuilder helper from context object
 * @returns {Promise<T | never> | * | *}
 */
export const getMembersList = ({ commit }, data) => {

  if (!data || !data.projectId)
    return null;

  commit(types.GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING)
  commit(types.GET_PROJECT_MEMBERS_LIST_SET_ERROR, '')
  let urlParams = ''

  if (data.urlParams)
    urlParams = data.urlParams

  let x = '/projects/' + data.projectId + '/members' + urlParams
  return fetch('/projects/' + data.projectId + '/members' + urlParams)
    .then((response) => {
      commit(types.GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      return data
    })
    .catch((e) => {
      commit(types.GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING)
      commit(types.GET_PROJECT_MEMBERS_LIST_SET_ERROR, e.message)
    })
}

export const fetchProjectMembers = ({ commit }, { projectId, urlParams = '' }) => {
  if (projectId === null) {
    commit(types.GET_PROJECT_MEMBERS_LIST_SET_ERROR, "No project found")
    return
  }
  return fetch('/projects/' + projectId + '/members' + urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING)
      commit(types.SET_PROJECT_MEMBERS_LIST, data['hydra:member'])
      return data
    })
    .catch((e) => {
      commit(types.GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING)
      commit(types.GET_PROJECT_MEMBERS_LIST_SET_ERROR, e.message)
    })
}

/**
 * @function updateProjectMember - update the member from a project
 * @param commit
 * @param {Object} data object that should contain the Project ID and ProjectMember ID
 * @returns {Promise<T | never> | * | *}
 */
export const updateProjectMember = ({ commit }, data) => {

  commit(types.UPDATE_MEMBER_LOADING)
  const userData = {}
  if (data.hasOwnProperty('role')) {
    userData.role = data.role
  } else {
    userData.isZooniverseCollaborator = data.isZooniverseCollaborator
  }

  return fetch(('/projects/' + data.projectId + '/members/' + data.id),
    {
      method: 'PATCH',
      body: JSON.stringify(userData)
    })
    .then((response) => {
      commit(types.UPDATE_MEMBER_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.UPDATE_MEMBER_DONE, data['hydra:member'])
      return data['hydra:member']
    })
    .catch((e) => {
      commit(types.UPDATE_MEMBER_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.UPDATE_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.UPDATE_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.UPDATE_MEMBER_SET_ERROR, e.message)
    })
}

/**
 * @function inviteProjectMember - send invitation to a user to join at the project
 * @param commit
 * @param rootGetters
 * @param {Object} data - object that contain the Project ID
 * @returns {Promise<T | never> | * | *}
 */
export const inviteProjectMember = ({ commit, rootGetters }, data) => {
  commit(types.INVITE_MEMBER_LOADING)
  return fetch(('/projects/' + rootGetters['project/get/id'] + '/invite'), { method: 'POST', body: JSON.stringify(data) })
    .then((response) => {
      commit(types.INVITE_MEMBER_LOADING)
      return response.status === 204
    })
    .then((data) => {
      commit(types.INVITE_MEMBER_DONE, data)
      return data
    })
    .catch((e) => {
      commit(types.INVITE_MEMBER_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.INVITE_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.INVITE_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.INVITE_MEMBER_SET_ERROR, e.message)
    })
}

/**
 * @function deleteDatasheet - delete a datasheet
 * @param commit
 * @param {String} datasheetId - a string with the Datasheet ID
 * @returns {Promise<T | never> | * | *}
 */
export const deleteDatasheet = ({ commit }, datasheetId) => {
  commit(types.DELETE_DATASHEET_LOADING)

  return fetch(('/datasheets/' + datasheetId), { method: 'DELETE' })
    .then((response) => {
      commit(types.DELETE_DATASHEET_LOADING)

      return response.status === 204
    })
    .then((data) => {
      commit(types.DELETE_DATASHEET_DONE, data)
      return true
    })
    .catch((e) => {
      commit(types.DELETE_DATASHEET_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DELETE_DATASHEET_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DELETE_DATASHEET_SET_ERROR, e.errors._error)
        return false
      }

      commit(types.DELETE_DATASHEET_SET_ERROR, e.message)

      return false
    })
}

/**
 * @function getProjectStats - get the project stats for the header info section
 * @param commit
 * @param {String} projectId - a string with the Project ID
 * @returns {Promise<T | never> | * | *}
 */
export const getProjectStats = ({ commit }, projectId) => {
  commit(types.GET_PROJECT_STATS_LOADING)

  return fetch(('/projects/' + projectId + '/stats'), { method: 'GET' })
    .then((response) => {
      commit(types.GET_PROJECT_STATS_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.GET_PROJECT_STATS_DONE, data)
      return data
    })
    .catch((e) => {
      commit(types.GET_PROJECT_STATS_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.GET_PROJECT_STATS_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.GET_PROJECT_STATS_SET_ERROR, e.errors._error)
        return false
      }

      commit(types.GET_PROJECT_STATS_SET_ERROR, e.message)

      return false
    })
}

/**
 * @function newUpload - upload image event
 * @param commit
 */
export const newUpload = ({ commit }) => {
  commit(types.PROJECT_PROFILE_TOGGLE_PICTURE_UPLOADING)
}

/**
 *
 * @param commit
 * @param error
 */
export const uploadInProgress = ({ commit }, error) => {
  commit(types.GET_PROJECT_STATS_SET_VIOLATIONS, error)
  // eslint-disable-next-line
  commit(types.GET_PROJECT_STATS_SET_ERROR, error)
}

/**
 *
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const uploadFile = ({ commit }, data = { isCreateRes: true, currentId: '', fd: new FormData() }) => {
  commit(types.PROJECT_SHOW_TOGGLE_LOADING, true)
  commit(types.PROJECT_SHOW_SET_ERROR, '')

  return fetch(('/file_objects' + (!data.isCreateRes ? ('/' + data.currentId) : '')), {
    method: 'POST',
    body: data.fd
  })
    .then((response) => {
      commit(types.PROJECT_SHOW_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      return data
    })
    .catch((e) => {
      commit(types.PROJECT_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.PROJECT_SHOW_SET_ERROR, e.message)
        // commit(types.RESOURCE_UPLOAD_SET_VIOLATIONS, e.errors)
        return
      }

      commit(types.PROJECT_SHOW_SET_ERROR, e.message)

      // return false
    })
}

/**
 *
 * @param commit
 */
export const getObservations = ({ commit }, data) => {
  // commit(types.GET_PROJECT_OBSERVATIONS_TOGLE_LOADING)
  // let id = data.id
  // let urlParams = data.urlParams
  //
  // return fetch('/projects/' + id + '/locations' + urlParams)
  //   .then(response => response.json())
  //   .then((data) => {
  //     commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
  //     commit(types.SET_PROJECT_LOCATIONS, data)
  //
  //     return data
  //   })
  //   .catch((e) => {
  //     commit(types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING)
  //     commit(types.PROJECT_SHOW_SET_ERROR, e.message)
  //   })
}


export const createAssignedLocationsForMember = ({ commit }, data) => {

  commit(types.UPDATE_MEMBER_LOADING)

  return fetch('/members_datasheets_locations', { method: 'POST', body: JSON.stringify(data) })
    .then((response) => {
      commit(types.UPDATE_MEMBER_LOADING)
      return response.json()
    }).then((data) => {
      return data
    })
    .catch((e) => {
      commit(types.UPDATE_MEMBER_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.UPDATE_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.UPDATE_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.UPDATE_MEMBER_SET_ERROR, e.message)
    })
}


export const updateAssignedLocationsForMember = ({ commit }, data) => {
  commit(types.UPDATE_MEMBER_LOADING)

  return fetch('/members_datasheets_locations/' + data.id, { method: 'PUT', body: JSON.stringify( data) })
    .then((response) => {
      commit(types.UPDATE_MEMBER_LOADING)
      return response.json()
    }).then((data) => {

      return data
    })
    .catch((e) => {
      commit(types.UPDATE_MEMBER_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.UPDATE_MEMBER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.UPDATE_MEMBER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.UPDATE_MEMBER_SET_ERROR, e.message)
    })
}

export const setError = ({ commit }, error) => {
  commit(types.UPDATE_MEMBER_SET_ERROR, error)
}

export const updateItem = ({ commit }, data) => {
  commit(types.UPDATE_ITEM_PROPERTY, data)
}


export const addLike = ({ commit }, value) => {
  commit(types.ADD_PROJECT_LIKE_ERROR, '');

  let values = Object.assign({}, value);
  values.user = JSON.parse(localStorage.getItem('sessionData'))["@id"];
  values.project = '/projects/' + window.ClickedProjectId;
  values.isLiked = true;

  return fetch('/project_likes', { method: 'POST', body: JSON.stringify(values) })
    .then(response => response.json())
    .then((data) => {
      commit(types.ADD_PROJECT_LIKE, data);
      if (typeof data === 'object') {
        return data;
      }
    })
    .catch((e) => {
      commit(types.ADD_PROJECT_LIKE_ERROR, e.message);
      return;
    })
};

export const removeLike = ({ commit }, id) => {
  commit(types.REMOVE_PROJECT_LIKE_ERROR, '');

  return fetch('/project_likes/' + id, { method: 'DELETE' })
    .then(response => response.status === 204)
    .then((data) => {
      commit(types.REMOVE_PROJECT_LIKE, data);
      return data;
    })
    .catch((e) => {
      commit(types.REMOVE_PROJECT_LIKE_ERROR, e.message);
      return;
    })
};

export const startNewDiscussion = ({ commit, rootGetters, dispatch }, discussion) => {
  commit(types.ADD_DISCUSSION_DATA_ERROR, '');
  commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);

  discussion.user = JSON.parse(localStorage.getItem('sessionData'))["@id"];
  discussion.project = `/projects/${discussion.projectId}`

  return dispatch("file/multiupload/uploadFiles", null, { root: true }).then(()=>{
    discussion.files = rootGetters["file/multiupload/uploadIRIS"]

    return fetch('/project_posts', { method: 'POST', body: JSON.stringify(discussion) })
      .then(response => response.json())
      .then((data) => {
        if (typeof data === 'object') {
          commit(types.ADD_DISCUSSION_DATA, data);
          commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
          commit("file/multiupload/REMOVE_UPLOADS", null, { root: true })
          // dispatch('project/forum/get', null, {root: true})
          return data;
        }
      })
      .catch((e) => {
        commit(types.ADD_DISCUSSION_DATA_ERROR, e.message);
        commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
        return;
      })
  })
};

export const getDiscussionForumData = ({ commit }, projectId) => {
  commit(types.GET_PROJECT_DISCUSSION_FORUM_DATA_ERROR, '');
  commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);

  return fetch('/projects/' + projectId + '/project_posts?order[createdAt]=desc', { method: 'GET' })
    .then(response => response.json())
    .then((data) => {
      if (typeof data === 'object') {
        commit(types.GET_PROJECT_DISCUSSION_FORUM_DATA, data['hydra:member']);
        commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
        return data['hydra:member'];
      }
    })
    .catch((e) => {
      commit(types.GET_PROJECT_DISCUSSION_FORUM_DATA_ERROR, e.message);
      return;
    })
};

export const removeDiscussionPost = ({ commit }, postId) => {
  commit(types.REMOVE_DISCUSSION_POST_ERROR, '');
  commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);

  return fetch('/project_posts/' + postId, { method: 'DELETE' })
    .then(response => response.status === 204)
    .then((data) => {
      if (data) {
        commit(types.REMOVE_DISCUSSION_POST, data);
        commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
        return data;
      }
    })
    .catch((e) => {
      commit(types.REMOVE_DISCUSSION_POST_ERROR, e.message);
      commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
      return;
    })
};

export const addReplyToDiscussion = ({ commit }, newReply) => {
  commit(types.ADD_REPLY_TO_DISCUSSION_ERROR, '');
  commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);


  newReply.user = JSON.parse(localStorage.getItem('sessionData'))["@id"];
  newReply.isEmailMembers = false;

  return fetch('/project_posts', { method: 'POST', body: JSON.stringify(newReply) })
    .then(response => response.json())
    .then((data) => {
      if (typeof data === 'object') {
        commit(types.ADD_REPLY_TO_DISCUSSION, data);
        commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
        return data;
      }
    })
    .catch((e) => {
      commit(types.ADD_REPLY_TO_DISCUSSION_ERROR, e.message);
      commit(types.ADD_DISCUSSION_DATA_TOGGLE_LOADING);
      return;
    })
};

/**
 * @function checkZooniverseUsername
 * @param commit
 * @param userData
 * @returns {Promise<T | never> | * | *}
 */
export const checkZooniverseUsername = ({ commit }, userData) => {
  commit(types.ZOONIVERSE_TOGGLE_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')

  return fetch('/users/' + userData.userId + '/zooniverse',
    {
      method: 'POST',
      body: JSON.stringify({ zooniverseUsername: userData.zooniverseUsername })
    })
    .then(response => response.json())
    .then((data) => {
      commit(types.ZOONIVERSE_TOGGLE_LOADING)
      return data
    })
    .catch((e) => {
      commit(types.ZOONIVERSE_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

/**
 * @function createZooniverseProject
 * @param commit
 * @param id
 * @returns {Promise<T | never> | * | *}
 */
export const createZooniverseProject = ({ commit }, id) => {
  commit(types.ZOONIVERSE_TOGGLE_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')

  return fetch('/projects/' + id + '/zooniverse', { body: JSON.stringify({}), method: 'POST' })
    .then(response => response.json())
    .then((data) => {
      commit(types.ZOONIVERSE_TOGGLE_LOADING)
      return data
    })
    .catch((e) => {
      commit(types.ZOONIVERSE_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

/**
 * @function createSciStarterProject
 * @param commit
 * @param id
 * @returns {Promise<T | never> | * | *}
 */
export const createSciStarterProject = ({ commit }, id) => {
  commit(types.SCISTARTER_TOGGLE_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')

  return fetch('/projects/' + id + '/scistarter', { body: JSON.stringify({}), method: 'POST' })
    .then(response => response.json())
    .then((data) => {
      commit(types.SCISTARTER_TOGGLE_LOADING)
      return data
    })
    .catch((e) => {
      commit(types.SCISTARTER_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

/**
 * @function sendImagesToZooniverse
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const sendImagesToZooniverse = ({ commit, dispatch }, data) => {
  commit(types.ZOONIVERSE_TOGGLE_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')


  return fetch('/projects/' + data.id + '/zooniverse/datasheets',
    {
      method: 'POST',
      body: JSON.stringify({ datasheets: data.datasheets })
    })
    .then(response => response.json())
    .then((data) => {
      dispatch('project/get/refresh', null, { root: true })

      commit(types.ZOONIVERSE_TOGGLE_LOADING)
      return data
    })
    .catch((e) => {
      commit(types.ZOONIVERSE_TOGGLE_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

/**
 * @function sendEmailToManager - send email to manager
 * @param commit
 * @returns {Promise<T | never> | * | *}
 */
export const sendEmailToManager = ({ commit }, data) => {
  commit(types.SEND_EMAIL_TO_MANAGER_LOADING)
  commit(types.PROJECT_SHOW_SET_ERROR, '')
  commit(types.SEND_EMAIL_TO_MANAGER_SUCCESS, '')
  let id = data.id

  return fetch('/projects/' + id + '/contact_manager',
    {
      method: 'POST',
      body: JSON.stringify(data.body)
    })
    .then(response => response.status === 204)
    .then((data) => {
      commit(types.SEND_EMAIL_TO_MANAGER_LOADING)
      if (data) {
        commit(types.SEND_EMAIL_TO_MANAGER_SUCCESS, 'Email sent to Manager Successfully')
      }
    })
    .catch((e) => {
      commit(types.SEND_EMAIL_TO_MANAGER_LOADING)
      commit(types.PROJECT_SHOW_SET_ERROR, e.message)
    })
}

/**
 * @function isLikedBeforeLogin
 * @param commit
 */
export const isLikedBeforeLogin = ({ commit }) => {
  commit(types.ADD_PROJECT_LIKE_BEFORE_LOGIN)
}
