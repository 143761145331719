import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import Vue from 'vue'

/**
 * @function retrieve
 * @param commit
 * @param page
 * @returns {Promise<T | never> | * | *}
 */
export const fetchSession = ({commit, rootState, dispatch},) => {
  commit(types.USER_PROFILE_READ_LOADING)
  let userId =rootState.user.auth.userId
  if (!userId) {
    // Return true / false - check if a JWT token is stored in cookies or local storage
    if (Vue.$jwt.hasToken()) {
      userId = Vue.$jwt.decode()['id']
    } else {
      dispatch("user/auth/triggerRouterLogout", null, {root: true})
    }
  }
  return fetch('/users/' + userId)
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_PROFILE_SET_RETRIEVED, data);
      return true
    })
    .catch((e) => {
      commit(types.USER_PROFILE_SET_ERROR, e.message);
      return false
    })
}
/**
 * @function update
 * @param commit
 * @param page
 * @param item
 * @returns {Promise<T | never> | * | *}
 */
export const update = ({commit}, {page, item}) => {
  commit(types.USER_PROFILE_UPDATE_LOADING)

  return fetch(page, {method: 'PUT', body: JSON.stringify(item)})
    .then(response => response.json())
    .then((data) => {
      // TODO: handle duplicate code - use a common function
      commit(types.USER_PROFILE_SET_RETRIEVED, data)
      // Toggle the isEditingProfile property only when email, username, first & last names are updated
      if (item.hasOwnProperty('receivesNewsletter') || item.hasOwnProperty('privacySetting')) {
      } else {
        commit(types.USER_PROFILE_TOGGLE_EDITING);
      }
    })
    .catch((e) => {
      if (e instanceof SubmissionError) {
        commit(types.USER_PROFILE_UPDATE_VIOLATIONS, e.errors);
        commit(types.USER_PROFILE_SET_ERROR, e.errors._error);
        return
      }
      commit(types.USER_PROFILE_SET_ERROR, e.message);
    })
}

/**
 * @function updatePassword
 * @param dispatch
 * @param commit
 * @param userId
 * @param item
 * @returns {Promise<T | never> | * | *}
 */
export const updatePassword = ({dispatch, commit}, {userId, item}) => {
  dispatch('resetUserUpdated')
  commit(types.USER_PROFILE_TOGGLE_RESET_PASSWORD_LOADING)


  const page = '/users/' + userId + '/password'

  return fetch(page, {method: 'PATCH', body: JSON.stringify(item)})
    .then(response => {
    })
    .then(() => {
      commit(types.USER_PROFILE_TOGGLE_RESET_PASSWORD_LOADING)
      commit(types.USER_PROFILE_RESET_PASSWORD_SET_UPDATED, true)
    })
    .catch((e) => {
      commit(types.USER_PROFILE_TOGGLE_RESET_PASSWORD_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.USER_PROFILE_RESET_PASSWORD_SET_VIOLATIONS, e.errors)
        commit(types.USER_PROFILE_RESET_PASSWORD_SET_ERROR, e.errors._error)

        return
      }

      commit(types.USER_PROFILE_RESET_PASSWORD_SET_ERROR, e.message)
    })
}

/**
 * @function toggleEdit
 * @param commit
 * @param status
 */
export const toggleEdit = ({commit}, status = null) => {
  if (status !== null) {
    commit(types.USER_PROFILE_TOGGLE_EDITING, status)
  } else {
    commit(types.USER_PROFILE_TOGGLE_EDITING)
  }
}

/**
 * @function toggleResetPassword
 * @param commit
 * @param status
 */
export const toggleResetPassword = ({commit}, status = null) => {
  if (status !== null) {
    commit(types.USER_PROFILE_TOGGLE_RESET_PASSWORD, status)
  } else {
    commit(types.USER_PROFILE_TOGGLE_RESET_PASSWORD)
  }
}

//** User Picture management */

/**
 * @function updateUserPicture
 * @param commit
 * @param state
 * @param rootState
 * @param newPicture
 * @returns {Promise<T | never> | * | *}
 */
export const updateUserPicture = ({dispatch, commit, state}, newPicture) => {
  commit(types.USER_PROFILE_PICTURE_UPLOADING)

  if (
    newPicture.name.lastIndexOf('.') <= 0 ||
    ['jpg', 'jpeg', 'png'].indexOf(newPicture.name.split('.').pop().toLowerCase()) === -1
  ) {
    commit(types.USER_PROFILE_PICTURE_SET_ERROR, 'Please select a jpg, jpeg, or png')
  }
  const form = new FormData()
  form.append('file', newPicture)
  form.append('actionType', 'avatar')

  let url = '/file_objects'
  // If a profile pic exists, replace the image in the existing file_object
  if (state.sessionData['picture']) {
    url = state.sessionData.picture['@id']
  }

  return fetch(url, {method: 'POST', body: form})
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      if (data.path) {
        commit(types.USER_PROFILE_PICTURE_SET_RETRIEVED, data)
      } else {
        commit(types.USER_PROFILE_PICTURE_SET_ERROR, "Error uploading: could not get picture URL")
      }
    })
    .catch((e) => {
      if (e instanceof SubmissionError) {
        commit(types.USER_PROFILE_PICTURE_SET_ERROR, e.errors._error)
        return
      }
      commit(types.USER_PROFILE_PICTURE_SET_ERROR, e.message)
    })
}

/**
 * @function removeUserPicture
 * @param commit
 * @returns {Promise<T | never> | * | *}
 */
export const removeUserPicture = ({commit, state}) => {
  commit(types.USER_PROFILE_PICTURE_UPLOADING)
  return fetch(state.sessionData.picture['@id'], {method: 'DELETE'})
    .then(() => {
      commit(types.USER_PROFILE_PICTURE_SET_RETRIEVED, null)
    })
    .catch((e) => {
      commit(types.USER_PROFILE_PICTURE_SET_ERROR, e.message)
    })
}

//** END User Picture management */


/**
 * @function reset
 * @param commit
 */
export const reset = ({commit}) => {
  commit(types.USER_PROFILE_RESET)
  localStorage.removeItem('sessionData')
}

/**
 * @function resetUserUpdated
 * @param commit
 */
export const resetUserUpdated = ({commit}) => {
  commit(types.USER_PROFILE_RESET_PASSWORD_SET_UPDATED, false)
  commit(types.USER_PROFILE_RESET_PASSWORD_SET_ERROR, '')
  commit(types.USER_PROFILE_RESET_PASSWORD_SET_VIOLATIONS, null)
}

/**
 * @function getAllUserProjects
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getAllUserProjects = ({commit, getters}) => {
  commit(types.USER_PROFILE_PROJECT_LOADING)
  let projects = []
  let errors = []

  // Fetch every project the current user is a member/manager of
  // TODO: maybe use Promise.all to fetch all the projects in a foreach
  getters.membershipArray.forEach((member) => {
    fetch('/projects/' + member.projectId)
      .then((response) => response.json())
      .then((data) => {
        projects.push(data)
      })
      .catch((e) => {
        errors.push(e)
      })
  })

  if (errors.length === 0) {
    commit(types.USER_PROFILE_SET_ALL_PROJECTS, projects)
    return projects
  } else {
    commit(types.USER_PROFILE_SET_ERROR, errors[0].message)
  }
}

/**
 * @function getUserProjects
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getUserProjects = ({commit}, data) => {
  if (getters.id === null) {
    commit(types.USER_PROFILE_SET_ERROR, "No user found")
    return
  }
  return fetch('/users/' + getters.id + '/projects')
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_PROFILE_SET_PROJECTS, data['hydra:member'])

      return data
    })
    .catch((e) => {
      commit(types.USER_PROFILE_SET_ERROR, e.message)
    })
}

/**
 * @function getUserLocations
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getUserLocations = ({commit, getters}, data) => {
  commit(types.USER_PROFILE_LOCATIONS_LOADING)
  if (getters.id === null) {
    commit(types.USER_PROFILE_SET_ERROR, "No user found")
    return
  }
  let urlParams = data.urlParams || ''
  let url = ''

  if (data.type == 'assigned') {
    url = ('/areas' + ((urlParams.length > 0 ? (urlParams + '&') : '?') + 'projectMembers.member.id=' + getters.id))
  } else {
    url = ('/areas' + ((urlParams.length > 0 ? (urlParams + '&') : '?') + 'observations.observers.user.id=' + getters.id))
  }

  return fetch(url)
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_PROFILE_SET_LOCATIONS, data)
      return data
    })
    .catch((e) => {
      commit(types.USER_PROFILE_SET_ERROR, e.message)
    })
}

/**
 * @function getUserObservations
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getUserObservations = ({commit, getters}, urlParams = '') => {
  if (getters.id === null) {
    commit(types.USER_PROFILE_SET_ERROR, "No user found")
    return
  }
  return fetch('/users/' + getters.id + '/observations' + urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_PROFILE_SET_OBSERVATIONS, data['hydra:member'])
      return data
    })
    .catch((e) => {
      commit(types.USER_PROFILE_SET_ERROR, e.message)
    })
}


/**
 * @function getUserStats
 * @param commit
 * @returns {Promise<T | never> | * | *}
 */
export const getUserStats = ({commit, getters}) => {
  commit(types.USER_PROFILE_STATS_LOADING)
  if (getters.id === null) {
    commit(types.USER_PROFILE_SET_ERROR, "No user found")
    return
  }
  return fetch('/users/' + getters.id + '/stats')
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_PROFILE_SET_STATS, data)
      return data
    })
    .catch((e) => {
      commit(types.USER_PROFILE_SET_ERROR, e.message)
    })
}
