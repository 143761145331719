<template lang="html">
<div class='px-3'>
    <InviteChild v-if='project'></InviteChild>
    <div class="row" v-else>
        <div class='col-12 d-flex align-items-center my-3'>
            <div class='flex-none'><i class="fa fa-spin fa-spinner fa-3x mr-3"></i></div>
            <div class='font-weight-bold flex-none'>Checking your invitation...</div>
        </div>
    </div>
</div>
</template>

<script>
// this parent component ensures the project is available to the subcomponent
import {
    mapActions,
    mapGetters
} from 'vuex'
import InviteChild from './invite.vue'

export default {
    components: {
        InviteChild
    },
    data() {
        return {
            token: null
        }
    },

    computed: {
        ...mapGetters({
            project: "project/get/project",

        })
    },
}
</script>

<style lang="">

</style>
