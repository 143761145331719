<template>
  <div v-if="userId">
    <div class="card-body">
      <div class="d-flex mb-2 justify-content-between">
        <h3>Pending Invites</h3>
      </div>
      <b-row align-h="between">
        <b-col md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} invite<span
          v-if="totalItems === 0 || totalItems > 1">s</span>
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-table
      show-empty
      stacked="md"
      id="userInvitesTable"
      ref="userInvitesTable"
      :busy.sync="isBusy"
      :items="getInvites"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(project)="row">
        <router-link
          :to="{ name: 'ProjectShow', params: { id: row.item.project.id } }"
        >
          {{ row.item.project.name }}
        </router-link>
      </template>
      <template v-slot:cell(options)="row">
        <div v-if="!row.item.isExpired" class="options-holder float-right">
          <b-btn
            size=""
            class="my-1 mr-1"
            @click="onAcceptInvite(row.item)"
            alt="Accept Project Join Invite"
            title="Accept Project Join Invite"
          >
            <i class="fa fa-check"></i> Accept
          </b-btn>
          <b-btn
            size=""
            class="my-1 mr-1"
            @click="onDeleteInvite(row.item)"
            alt="Deny Project Join Invite"
            title="Deny Project Join Invite"
          >
            <i class="fa fa-trash"></i> Reject
          </b-btn>
        </div>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Invites..."/>
      </template>
      <template #empty>
        <h4 class="text-center">
          There are no invites.
        </h4>
      </template>
    </b-table>

    <b-row class="mx-auto" align-h="between">
      <b-col md="3" class="my-1">
        Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} invite<span
        v-if="totalItems === 0 || totalItems > 1">s</span>
      </b-col>

      <b-col sm="2" md="3" class="my-1">
        <b-pagination
          :total-rows="totalItems"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0 pagination-holder"
          prev-text="Prev"
          next-text="Next"
          align="fill"
        />
      </b-col>

      <b-col sm="2" md="3" class="my-1">
        <b-form-group
          label="Per Page"
          label-align="right"
          label-cols-sm="7"
          label-cols-md="8"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import VueLoader from "../../../layout/Loader.vue";

export default {
  components: {VueLoader},
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      tableFields: [
        {
          key: "createdAt",
          label: "Date Created",
          formatter: val => {
            return this.displayDate(val);
          },
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "project",
          label: "Project",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "inviteMessage",
          label: "Message",
          sortable: false,
          sortDirection: "asc"
        },
        {
          key: "isExpired",
          label: "Status",
          formatter: val => {
            if (val) {
              return "Expired"
            } else {
              return "Active"
            }
          },
          sortable: false,
          sortDirection: "asc"
        },
        {key: "options", label: "Options", sortable: false, class: "text-right"}
      ]
    }
  },
  created() {
  },
  methods: {
    ...mapActions({
      getUserInvites: 'user/project_invites/getUserInvites',
      acceptInvite: 'project/show/joinToProject',
      rejectInvite: 'project/invites/delete',
    }),

    getInvites(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {urlParams};
      return this.getUserInvites(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(invites => {
          return {
            ...invites
          };
        });
      });
    },

    onAcceptInvite(selectedInvite) {
      let data = {id: selectedInvite.project.id, data: {token: selectedInvite.token}}
      this.acceptInvite(data).then((data) => {
        if (data) this.reloadTable()
      });
    },

    onDeleteInvite(selectedInvite) {
      let data = {id: selectedInvite.id}
      this.rejectInvite(data).then((data) => {
        if (data) this.reloadTable()
      });
    },

    reloadTable() {
      this.$refs.userInvitesTable.refresh();
    },
  },
  computed: {
    ...mapGetters({
      userId: 'user/profile/id',
      isLoading: 'user/project_invites/isLoading',
      invites: 'user/project_invites/invites',
      error: 'user/project_invites/error',
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    }
  },
}
</script>

<style scoped lang="scss">

</style>
