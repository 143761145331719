<template>
  <div v-if="projectVuex">
    <div v-if="isPublicOrMember" class="card-body">
      <div class="row mb-3">
        <!-- Sidebar TagFilter, PopularTags -->
        <div class="col-lg-3 d-none d-lg-block sidebar">
          <button class="btn btn-primary has-icon btn-block mb-2" @click="$bvModal.show('create-project-resource')">
            <i class="fa fa-plus text-white"></i>
            New Resource
          </button>
          <div class="horizontal-separator"></div>
          <TagsFilter :set-tag="tagClick" @selectedTag="handleTagFilter"></TagsFilter>
          <TagsPopular @popularTagClicked="handlePopularTagClick"></TagsPopular>
        </div>

        <!-- Resources Table/Card View -->
        <div class="col-lg-9 col-md-12">
          <div class="row align-items-center justify-content-between mb-3" v-if="isManager">
            <div class="col-sm-4 d-lg-none">
              <button class="btn btn-primary has-icon mb-2" @click="$bvModal.show('create-project-resource')">
                <i class="fa fa-plus text-white"></i>
                New Resource
              </button>
            </div>
          </div>
          <div class="row align-items-center justify-content-between mb-3">
            <div class="input-group col-xs-12 col-sm-12 col-md-6 mb-2">
              <b-input-group-prepend>
                  <span class="btn btn-secondary" title="Search Project Resources">
                    <i v-if="!isBusy" class="fas fa-search"></i>
                    <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
                  </span>
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search Project Resources"
                name="search"
                type="search"
                v-model="searchText"
                debounce="1000"
              ></b-form-input>
              <div class="input-group-append" v-if="searchText">
                <b-button @click="searchText = ''"
                ><i class="fas fa-times-circle"></i>
                </b-button>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 mb-2">
              <b-form-group
                v-if="resourceShowCard"
                label="Sort By: "
                label-for="sort-by-select"
                label-cols-sm="2"
                label-cols-md="2"
                label-align-sm="right"
                label-size="sm"
                class="mb-0 font-weight-bold"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    :aria-describedby="ariaDescribedby"
                    class="col-xs-8 col-md-10"
                  >
                  </b-form-select>

                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :aria-describedby="ariaDescribedby"
                    size="sm"
                    class="col-xs-4 col-md-2"
                  >
                    <option :value="false">asc</option>
                    <option :value="true">desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>
          </div>

          <div class="row justify-content-between">
            <b-col md="3" class="my-1">
              Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }}
              resource<span v-if="totalItems === 0 || totalItems > 1">s</span>
              <span v-if="filterTag"> (filtered by tag '{{ filterTag }}') </span>
            </b-col>

            <b-col sm="2" md="3" class="my-1">
              <b-pagination
                :total-rows="totalItems"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0 pagination-holder"
                prev-text="Prev"
                next-text="Next"
                align="fill"
              />
            </b-col>

            <b-col sm="2" md="3" class="my-1">
              <b-form-group
                label="Per Page"
                label-align="right"
                label-cols-sm="7"
                label-cols-md="8"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </div>

          <b-table v-show="!resourceShowCard"
                   show-empty
                   stacked="md"
                   id="projectResourcesTable"
                   ref="projectResourcesTable"
                   :filter="filterVal"
                   :busy="isBusy"
                   :items="resources"
                   :fields="tableFields"
                   :current-page="currentPage"
                   :per-page="perPage"
                   sort-icon-left
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :sort-direction="sortDirection"
          >
            <template #cell(file.mimeType)="data">
              <b-badge v-if="data.value" variant="secondary">{{ data.value }}</b-badge>
            </template>
            <template #cell(tags)="row">
<!--              <div class="col-sm-2" v-for="(tag, index) in row.item.tags" :key="index">-->
                <b-badge v-for="(tag, index) in row.item.tags" :key="index"
                  class="text-xsmall-bold btn btn-tertiary mr-1"
                  size="sm" pill
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Click to Filter Resources by this Tag"
                  href="#"
                  @click="handleTagClick(tag)">{{ tag }}
                </b-badge>
<!--              </div>-->
            </template>
            <template v-slot:cell(options)="row">
              <b-dropdown variant="outline" size="sm" no-caret dropleft>
                <template #button-content>
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </template>
                <b-dropdown-item
                  class="mt-1 mr-1"
                  @click="onViewResource(row.item)"
                  alt="View Resource"
                  title="View Resource"
                >
                  <font-awesome-icon icon="eye"/>&nbsp;&nbsp;View
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="isManager"
                  class="mt-1 mr-1"
                  @click="onEditResource(row.item)"
                  alt="Edit this Resource"
                  title="Edit this Resource"
                >
                  <i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="isManager"
                  class="mt-1"
                  @click="onClickDelete(row.item)"
                  alt="Delete this Resource from the Project"
                  title="Delete this Resource from the Project"
                >
                  <i class="fa fa-trash"></i>&nbsp;&nbsp;Delete
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #table-busy>
              <VueLoader style="height: 200px" text="Loading Resources..."/>
            </template>
          </b-table>

          <div v-show="resourceShowCard">
            <div v-if="isBusy">
              <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading resources...
            </div>
            <div v-else>
              <div class="row justify-content-center">
                <div class="mb-2"
                     v-for="resourceValue in cards"
                     :key="resourceValue.id">
                  <ResourceCard :resource=resourceValue
                                :handle-view="onViewResource"
                                :handle-edit="onEditResource"
                                :handle-delete="onClickDelete"
                                @tagClick="handleTagClick"
                  ></ResourceCard>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row justify-content-between">
              <b-col md="3" class="my-1">
                Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }}
                resource<span v-if="totalItems === 0 || totalItems > 1">s</span>
                <span v-if="filterTag"> (filtered by tag '{{ filterTag }}') </span>
              </b-col>

              <b-col sm="2" md="3" class="my-1">
                <b-pagination
                  :total-rows="totalItems"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0 pagination-holder"
                  prev-text="Prev"
                  next-text="Next"
                  align="fill"
                />
              </b-col>

              <b-col sm="2" md="3" class="my-1">
                <b-form-group
                  label="Per Page"
                  label-align="right"
                  label-cols-sm="7"
                  label-cols-md="8"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="deleteResourceModal" title="Delete Resource" centered>
        <div v-if="selectedResource">
          <p class="my-4">
            Do you want to remove this resource from the project? This cannot be
            undone.
          </p>
          <div>
            <span class="font-weight-bold">Date Uploaded: </span>
            {{ displayDate(selectedResource.createdAt) }}
          </div>
          <div>
            <span class="font-weight-bold">Resource Name: </span>
            {{ selectedResource.name }}
          </div>
        </div>
        <template slot="modal-footer">
          <div>
            <b-btn variant="danger" @click="onConfirmDelete()">Yes, delete</b-btn>
            <b-btn @click="closeModal">No</b-btn>
          </div>
        </template>
      </b-modal>
      <CreateProjectResourceModal @refresh-table="reloadTable"></CreateProjectResourceModal>
      <EditProjectResourceModal :resource="getResourceToEdit" @refresh-table="reloadTable"></EditProjectResourceModal>
    </div>
    <RequiresMember v-else page="Resources"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader";
import CreateResourceModal from "../../CreateResourceModal";
import EditResource from "../../EditResource";
import RequiresMember from "../tabpermission/requiresMember.vue";
import CreateProjectResourceModal from "./createProjectResourceModal.vue";
import EditProjectResourceModal from "./editProjectResourceModal.vue";
import ResourceCard from "./resourceCard.vue";
import TagsPopular from "./tagsPopular.vue";
import TagsFilter from "./tagsFilter.vue";
import NewDiscussionButton from "../forum/newDiscussionButton.vue";

export default {
  name: "ResourcesTable",
  components: {
    NewDiscussionButton,
    TagsFilter, TagsPopular,
    ResourceCard,
    CreateProjectResourceModal,
    EditProjectResourceModal,
    RequiresMember,
    VueLoader,
    CreateResourceModal,
    EditResource
  },
  props: {},
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      showModal: null,
      selectedResource: null,
      tagClick: null,
      tableFields: [
        {key: "name", label: "Name", sortable: true},
        {
          key: "type",
          label: "Resource Type",
          sortable: false,
          formatter: val => {
            return val.charAt(0).toUpperCase() + val.slice(1)
          }
        },
        {
          key: "file.mimeType",
          label: "File Type",
          sortable: true,
          formatter: val => {
            // Convert mime type to simpler values
            // - For example application/pdf to just pdf
            return this.convertMimeType(val);
          }
        },
        {
          key: "updatedAt",
          label: "Date Updated",
          sortable: true,
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "tags",
          label: "Tags",
          sortable: false,
          thStyle: { width: "30%" },
          class: "text-left",
          tdClass: "text-left",
        },
        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right",
        }
      ],
      resourceToEdit: null
    };
  },
  created() {
    if (this.projectVuex) {
      this.resourceTags()
    }
    if (this.resourceTag) {
      // Trigger child component
      this.tagClick = this.resourceTag
    }
  },
  watch: {
    projectVuex(newVal) {
      if (newVal) {
        this.resourceTags()
      }
    },
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      projectId: "project/get/id",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      resourcesCard: "project/resources/resources",

      resourceShowCard: "project/get/resourceShowCard",
      resourcePage: "project/get/resourcePage",
      resourceItems: "project/get/resourceItems",
      resourceTotal: "project/get/resourceTotal",
      resourceSearch: "project/get/resourceSearch",
      resourceTag: "project/get/resourceTag",
      resourceSortBy: "project/get/resourceSortBy",
      resourceSortDirection: "project/get/resourceSortDirection",
      resourceSortDesc: "project/get/resourceSortDesc",
    }),
    currentPage: {
      get() {
        return this.resourcePage
      },
      set(newValue) {
        this.setResourceTablePage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.resourceTotal
      },
      set(newValue) {
        this.setResourceTableTotal(newValue)
      }
    },
    perPage: {
      get() {
        return this.resourceItems
      },
      set(newValue) {
        this.setResourceTableItems(newValue)
      }
    },
    searchText: {
      get() {
        return this.resourceSearch
      },
      set(newValue) {
        this.setResourceTableSearch(newValue)
      }
    },
    filterTag: {
      get() {
        return this.resourceTag
      },
      set(newValue) {
        this.setResourceTableTag(newValue)
      }
    },
    sortBy: {
      get() {
        return this.resourceSortBy
      },
      set(newValue) {
        this.setResourceSortBy(newValue)
      }
    },
    sortDirection: {
      get() {
        return this.resourceSortDirection
      },
      set(newValue) {
        this.setResourceSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.resourceSortDesc
      },
      set(newValue) {
        this.setResourceSortDesc(newValue)
      }
    },

    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search observations by location name
        // - filter by tags

        let retVal = ''
        if (this.searchText.length > 0) {
          retVal = retVal.length === 0 ? 'search=' + this.searchText : retVal + '&search=' + this.searchText
        }
        if (this.filterTag) {
          retVal = retVal.length === 0 ? 'tags=' + this.filterTag : retVal + '&tags=' + this.filterTag
        }
        return retVal
      }
    },
    cards() {
      if (this.resourcesCard) {
        return this.resourcesCard['hydra:member']
      } else {
        return []
      }
    },
    getResourceToEdit() {
      return this.resourceToEdit
    },
    sortOptions() {
      // Create an options list from our fields
      return this.tableFields
        .filter(f => f.sortable)
        .map(f => {
          return {text: f.label, value: f.key}
        })
    }
  },
  methods: {
    ...mapActions({
      fetchResources: "project/resources/get",
      deleteResource: "project/resources/delete",

      resourceTags: "project/resources/tags",

      setResourceTablePage: "project/get/setResourceTablePage",
      setResourceTableItems: "project/get/setResourceTableItems",
      setResourceTableTotal: "project/get/setResourceTableTotal",
      setResourceTableSearch: "project/get/setResourceTableSearch",
      setResourceTableTag: "project/get/setResourceTableTag",
      setResourceSortBy: "project/get/setResourceTableSortBy",
      setResourceSortDirection: "project/get/setResourceTableSortDirection",
      setResourceSortDesc: "project/get/setResourceTableSortDesc",
    }),
    handleTagFilter(tag) {
      // Handle TagFilter selection
      // - Skip if values match, prevent infinite loop
      // - TagFilter will emit when a PopularTag/PostTag is clicked
      if (this.filterTag !== tag) {
        this.filterTag = tag
        if (this.tagClick && !tag) {
          // External tag is cleared
          this.tagClick = null
        }
      }
    },
    handlePopularTagClick(tag) {
      console.log('popular tag click', tag)
      // Notify TagFilter component of Popular Tag clicked
      this.tagClick = tag
      // Filter the resources in this component
      this.filterTag = tag
    },
    handleTagClick(tag) {
      // Notify TagFilter component of Post Tag clicked
      this.tagClick = tag
      // Filter the resources in this component
      this.filterTag = tag
    },
    resources(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {urlParams};
      console.log('urlParams', data)
      return this.fetchResources(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(resource => {
          return {
            ...resource
          };
        });
      });
    },
    onViewResource(resource) {
      let href = "";
      if (resource.type === "file") {
        href = resource.file.path;
      } else {
        href = resource.url;
      }
      window.open(href, "_blank");
    },
    onEditResource(resource) {
      this.resourceToEdit = {...resource}
      this.$bvModal.show('edit-project-resource')
    },
    onSaveEditResource() {
      this.reloadTable()
    },
    reloadTable() {
      this.resourceTags()
      this.$refs.projectResourcesTable.refresh();
    },
    closeModal() {
      this.$bvModal.hide("deleteResourceModal");
      this.selectedResource = null;
    },
    onClickDelete(resource) {
      this.selectedResource = {...resource};
      this.$bvModal.show("deleteResourceModal");
    },
    onConfirmDelete() {
      this.deleteResource({id: this.selectedResource.id}).then(() => {
        this.reloadTable()
      });
      this.closeModal();
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  overflow-y: auto;
  border-right: 1px solid #5a5b54;
}

.horizontal-separator {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #5a5b54;
}

.text-xsmall-bold {
  font-size: x-small;
  font-weight: bold;
}
</style>
