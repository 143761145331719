<template>

  <b-modal id="not-verified-modal" title="Please Verify Your Account"
           size="lg" :ok-only="true" ref="notVerifiedModal" >

    <b-container>
      <b-row class="justify-content-lg-center">
        <b-col col lg="9">
          <b-card id="activate-loader" bg-variant="light">

            <b-form-group>

              <div v-show="isLoading">
                <font-awesome-icon icon="spinner" pulse />
                Loading
              </div>

              <div>
                <span>
                  <b>Thank you for being a part of CitSci!</b>
                  <br><br>
                  For security reasons, some site features are restricted for unverified accounts.<br>
                  Verify your account to access these features.<br><br>
                  Follow these steps to verify your account,<br>
                  1. Click the button below to receive an Account Verification Email.<br>
                  2. Follow instructions in the email to verify your account.<br><br>
                </span>
                <div v-if="!emailSent">
                  <b-button type="submit"
                            class="ResendEmail"
                            v-b-tooltip title="Send Account Verification Email"
                            @click="onClickResendEmail"
                            variant="primary">Send Verification Email
                  </b-button>
                </div>
                <div v-else>
                  <span>
                    <b>Account Verification Email Was Sent Successfully!</b>
                    <br>
                    Be sure to check your spam folder for the Verification email.<br>
                    Add webmaster@citsci.org to your email safe sender's list.
                  </span>
                  <br><br>
                  <i>Note: Account Verification link's expire after 30 days</i>
                </div>
              </div>
              <div v-if="error">{{ error }}</div>
            </b-form-group>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </b-modal>

</template>

<script>

  import { mapActions, mapGetters } from 'vuex'

  export default {

    data () {
      return {
        verifiedStatus: false
      }
    },

    computed: {
      ...mapGetters({
        error: 'user/activate/error',
        isLoading: 'user/activate/isLoading',
        emailSent: 'user/activate/emailSent',
      }),
    },

    methods: {
      ...mapActions({
        resendEmail: 'user/activate/resendEmail'
      }),

      onClickResendEmail() {
        let id = JSON.parse(localStorage.getItem('sessionData')).id
        let email = JSON.parse(localStorage.getItem('sessionData')).email
        this.resendEmail({ "id": id, "email": email }).then(()=>{
        })
      },
    }

  }
</script>
