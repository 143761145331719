<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 mr-0 pr-0">
        <div class="rounded p-2 h-100 border">
          <h5>Legend</h5>
          <div class="row">
            <div class="col-sm-12">
              <div class="mb-1">
                <img src="/assets/images/unselected-project-marker.png" width="12"/> Project Headquarters
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="mb-1">
                <img src="/assets/images/ObsLocationIcon5.png" width="12"/> Many Observations
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="mb-1">
                <img src="/assets/images/ObsLocationIcon3.png" width="12"/> Single Observation
              </div>
            </div>
          </div>

          <div class="mt-4" v-if="resourceMaps && resourceMaps.length > 0">
            <b-form-group label="Map Layers" label-for="feature-layers" label-class="h5">
              <b-form-checkbox-group id="feature-layers" v-model="layerChecked" :options="featureLayers" stacked>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-md-9">
        <l-map ref="map" id="map"
               style="height: 400px; width: 100%; background-color: #F5F4E8; border: solid 1px #DFDFDF; z-index:800 !important;"
               :zoom="zoom"
               :center="center"
               @ready="setupMap()"
               :bounds="autoBounds()">

          <l-tile-layer
            :url="url"
            :attribution="attribution">
          </l-tile-layer>

          <l-control-layers
            :collapsed=false>
          </l-control-layers>

          <l-wms-tile-layer
            v-for="tile in tileProviders"
            :key="tile.name"
            :base-url="tile.baseUrl"
            :layers="tile.layers"
            :visible="tile.visible"
            :name="tile.name"
            layer-type="base"
            :opacity="0.5"
          />

          <l-feature-group ref="featureGroup" id="featureGroup"></l-feature-group>

          <l-marker v-if="locations.length > 0"
                    v-for="(location, index) in locations"
                    :key="index"
                    :lat-lng="[location.latitude, location.longitude]">
            <l-popup>
              <h3>{{ location.name }}</h3>
              <p class="popupText">
                <strong>Coords:</strong> {{ location.latitude }}, {{ location.longitude }}<br/>
                <strong># Observations:</strong> {{ location.observationsTotal }}
              </p>

              <router-link
                :to="{ name: 'LocationShow', params: { id: location.id } }"
                class="btn btn-primary text-nowrap"
                alt="View Location Details"
                title="View Location Details"
              >
                <font-awesome-icon icon="eye"/>
                View details
              </router-link>
              <b-link
                class="btn btn-secondary text-nowrap"
                style="color:white;"
                :href="`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`"
                target="_blank" rel="noopener noreferrer"
              >Directions
              </b-link>
            </l-popup>

            <l-icon v-if="location.observationsTotal > 1"
                    icon-url="/assets/images/ObsLocationIcon5.png"
                    :icon-size="[12,12]"
            />
            <l-icon v-else-if="location.observationsTotal = 1"
                    icon-url="/assets/images/ObsLocationIcon3.png"
                    :icon-size="[12,12]"
            />
            <l-icon v-else
                    icon-url="/assets/images/ObsLocationIcon6.png"
                    :icon-size="[12,12]"
            />
          </l-marker>

          <l-marker :lat-lng="center" style="z-index:10 !important;">
            <l-icon
              icon-url="/assets/images/unselected-project-marker.png"
              :icon-size="[20,34]">
            </l-icon>
          </l-marker>
        </l-map>

      </div>
    </div>
  </div>
</template>

<script>

import * as L from 'leaflet';
import * as EsriLeaflet from 'esri-leaflet';
import * as EsriLeafletRenderers from 'esri-leaflet-renderers';
import {latLngBounds} from 'leaflet';
import {LMap, LTileLayer, LMarker, LIcon, LPopup, LWMSTileLayer, LControlLayers, LFeatureGroup,} from 'vue2-leaflet';

import {mapActions, mapGetters} from "vuex";

export default {
  name: "MyLeafletMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    'l-wms-tile-layer': LWMSTileLayer,
    LControlLayers,
    LFeatureGroup,
  },
  props: {
    latitude: {
      type: Number,
      default: () => {
        return 42.0;
      }
    },
    longitude: {
      type: Number,
      default: () => {
        return -92.0;
      }
    },
    locations: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      selectedLocation: null,
      layerChecked: [],
      attribution:
        '&copy; <a target="_blank" rel="noopener noreferrer" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      center: [this.latitude, this.longitude],
      addedLayers: [],

      //https://services1.arcgis.com/KNdRU5cN6ENqCTjk/arcgis/rest/services/New_Mexico_Aquifer/FeatureServer/0
      //http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi
      //https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}
      //baseUrl:'http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi',
      tileProviders: [
        {
          //https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}
          name: 'OSM Topo',
          baseUrl: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          visible: true,
          format: 'image/png',
          layers: '',
          transparent: true,
          attribution: "OSM",
          selected: true
        },
        {
          name: 'ESRI World',
          baseUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          visible: false,
          format: 'image/png',
          layers: '',
          transparent: true,
          attribution: "ESRI"
        },
        {
          //https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}
          name: 'ESRI Topo',
          baseUrl: 'http://services.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}',
          visible: false,
          format: 'image/png',
          layers: '',
          transparent: true,
          attribution: "ESRI",
          selected: false
        },
      ]
    };
  },
  created() {
    // Vector and Raster types are fetched
    this.getMapTypes();
  },

  watch: {
    layerChecked(newVal, oldVal) {
      let layersToAdd = newVal.filter(l => !oldVal.includes(l))
      layersToAdd.forEach(val => {
        this.resourceMaps.filter(res => {
          if (val === res.id) {
            this.addMapLayer(res.url)
          }
        })
      })
      let layersToRemove = oldVal.filter(l => !newVal.includes(l))
      layersToRemove.forEach(val => {
        this.resourceMaps.filter(res => {
          if (val === res.id) {
            this.removeMapLayer(res.url)
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters({
      project: "project/get/project",
      resourceMaps: "project/resources/resourceMaps",
    }),
    featureLayers() {
      if (this.resourceMaps && this.resourceMaps.length > 0) {
        return this.resourceMaps.map(layer => {
          return {text: layer.name, value: layer.id}
        })
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions({
      getMapTypes: "project/resources/getMapTypes",
    }),

    setupMap() {
      // Map object is set and feature group is added to the map
      this.map = this.$refs.map['mapObject'] // works as expected
      L.featureGroup().addTo(this.map); // this.map
    },

    addMapLayer(resourceUrl) {
      let featureLayer = EsriLeaflet.featureLayer({
        url: resourceUrl,
        onEachFeature: (feature, layer) => {
          // Add any additional properties or handling here
        },
      })
      this.addedLayers.push({url: resourceUrl, layer: featureLayer})
      featureLayer.addTo(this.$refs.featureGroup.mapObject);

      // go get map layers from project resources where type=map layer and add each

      // TODO: RASTER
      // let newLayer = {
      //   name: 'Weather Data',
      //   baseUrl: 'http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi',
      //   visible: false,
      //   format: 'image/png',
      //   layers: 'nexrad-n0r-900913',
      //   transparent: false,
      //   attribution: "Weather data © 2012 IEM Nexrad"
      // }

      // this.layers.push(newLayer);

      // this.map = this.$refs.map.mapObject // works as expected

      // const featureLayerUrl = 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/US_Drought_Intensity_v1/FeatureServer/3';

      // // Add feature layer using esri-leaflet
      // EsriLeaflet.featureLayer({
      //   url: featureLayerUrl,
      //   onEachFeature: (feature, layer) => {
      //     // Add any additional properties or handling here
      //   }
      // }).addTo(this.$refs.featureGroup.mapObject);

      // let droughtLayer = {
      //   name: 'Current Drought',
      //   baseUrl: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/US_Drought_Intensity_v1/FeatureServer/3',
      //   visible: true,
      //   transparent: true,
      //   attribution: 'ESRI Living Atlas'
      // }

    },

    removeMapLayer(resourceUrl) {
      let featureLayer = this.addedLayers.filter(l => {
        return l.url === resourceUrl
      })[0]
      featureLayer.layer.removeFrom(this.$refs.featureGroup.mapObject)
      this.addedLayers.splice(this.addedLayers.indexOf(featureLayer), 1)
    },

    autoBounds() {
      if (this.locations && this.locations.length > 0) {
        // Returns the bounds for the map using all the lat+lng
        let allCoords = this.locations.map(loc => {
          return {lat: loc.latitude, lng: loc.longitude}
        })
        return latLngBounds(allCoords)
      } else {
        // If no locations present, return the HQ location as the bounds
        return [[this.latitude, this.longitude], [this.latitude, this.longitude],]
      }
    }
  },
};

</script>

<style lang="">
.locationMap {
  width: 100%;
  height: 60vh;
}

.loadingMarkers {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.locationDetails {
  min-width: 400px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.popupText {
  font-size: 10pt;
  color: grey;
}

@media (min-width: 768px) {
  .mapLegend {
  // margin-top:-168px; /*-168px -180px;*/ // z-index:88 !important; // position:relative; // margin-bottom:12px; width: 100%; /* 260px; */ float: left;
  }
}

.mapLegendIcon {
  min-width: 12px !important;
  max-width: 12px !important;
  width: 12px;
  display: inline-block;
  text-align: center;
}

.mapLegendLabel {
  float: right;
  width: 89%;
  text-align: left !important;
  vertical-align: middle;
  margin-top: 2px;
}
</style>
