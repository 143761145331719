<template>
  <div class="card bg-light">
    <div v-if="!projectVuex.zooniverseID" class="card-body">
      <u><b>Zooniverse Project:</b></u> Not Created
    </div>
    <div v-if="projectVuex.zooniverseID" class="card-body">
      <div>
        <u><b>Zooniverse Project:</b></u> Created
        <i class="fa fa-check ml-2"></i>
      </div>
      <div><b>Project ID: </b>{{ projectVuex.zooniverseID }}</div>
    </div>

    <div v-if="projectVuex.zooniverseUserName" class="card-footer">
      <b-btn v-if="projectVuex.zooniverseUserName &&  projectVuex.zooniverseID"
             class="btn btn-secondary"
             size="md"
             :href="'https://www.zooniverse.org/lab/' + projectVuex.zooniverseID"
             target="_blank" rel="noopener noreferrer"
      >
        Visit Zooniverse Project
        <span><i class="fas fa-external-link-alt fa-xs"></i></span>
      </b-btn>
      <b-btn
        v-else
        class="btn btn-primary"
        @click="sendZooniverseProject"
        :class="{ disabled: isLoadingProjectId }"
        :disabled="isLoadingProjectId"
      >
        Create Zooniverse Project
        <i v-if="isLoadingProjectId" class="fas fa-spinner fa-spin ml-2"></i>
      </b-btn><br>
      <span v-if="errorProject">{{errorProject}}</span>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ZooniverseProject",
  props: {},
  components: {},
  data() {
    return {
    };
  },
  created() {
  },
  watch: {},

  computed: {
    ...mapGetters({
      isLoading: "project/get/isLoading",
      projectVuex: "project/get/project",
      isLoadingProjectId: "project/get/zooniverse/isLoadingProjectId",
      errorProject: "project/get/zooniverse/messageLoadingProject",
    }),
  },

  methods: {
    ...mapActions({
      createZooniverseProject: "project/get/zooniverse/setProjectId",
    }),
    sendZooniverseProject() {
      if (!this.projectVuex.zooniverseUserName) {
        this.$bvModal.msgBoxOk(
          "A Zooniverse Collaborator must be set before a Zooniverse Project can be created.",
          {
            title: "Zooniverse Collaborator Not Set",
            size: "md",
            okVariant: "info",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true
          }
        );
        return;
      }

      // Zooniverse project is created
      this.createZooniverseProject()
    },
  }
};
</script>

<style lang="scss" scoped></style>

