import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    invites: null,
    error: null,
  }
}

const state = getInitialState();

const getters = {
  invites(state) {
    return state.invites
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

const actions = {
  getUserInvites({ commit, rootGetters }, data) {
    commit(types.SET_INVITES_LOADING);
    return fetch('/users/' + rootGetters['user/profile/id'] + '/invites' + data.urlParams)
      .then(response => response.json())
      .then((invites) => {
        commit(types.SET_INVITES, invites)
        return invites
      })
      .catch((error) => {
        commit(types.SET_INVITES_ERROR, error)
      })
  },

  refresh({commit }) {
    commit(types.RESET);
  },
}

const types = {
  SET_INVITES_LOADING: 'SET_INVITES_LOADING',
  SET_INVITES: 'SET_INVITES',
  SET_INVITES_ERROR: 'SET_INVITES_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}
const mutations = {
  [types.SET_INVITES_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_INVITES](state, invites) {
    state.invites = invites;
    state.isLoading = false;
  },
  [types.SET_INVITES_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
