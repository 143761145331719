<template>
  <b-navbar toggleable="md" type="dark" fixed="top">
    <div class="nav-content">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <router-link to="/">
        <v-img
          class="mr-2 site-logo"
          src="/assets/images/logo.png"
          width="40"
          height="40"
          title="CitSci.org"
          alt="CitSci.org"
          href="/"
        ></v-img>
      </router-link>

      <b-navbar-brand
        class="site-title"
        to="/"
        title="CitSci.org"
        alt="CitSci.org"
      >
        CitSci
      </b-navbar-brand>

      <b-collapse is-nav id="nav-collapse">
        <b-navbar-nav>
          <b-nav-item-dropdown>
            <template slot="button-content">
              <span alt="About Us" title="About Us">About</span>
            </template>
            <b-dropdown-item
              :to="{ name: 'About' }"
              alt="About Us"
              title="About Us"
              >About Us</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Impact' }"
              alt="Our Impact"
              title="Our Impact"
              >Our Impact</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Mission' }"
              alt="Mission"
              title="Mission"
              >Mission</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Staff' }"
              alt="Team"
              title="Team"
              >Team</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Partners' }"
              alt="Partners"
              title="Partners"
              >Partners</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Publications' }"
              alt="Publications"
              title="Publications"
              >Publications</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            v-for="(tab, index) in mainNavigationTabs"
            :key="index"
            :to="tab.to"
            :alt="tab.title"
            :title="tab.title"
            >{{ tab.title }}</b-nav-item
          >
          <b-nav-item href="https://blog.citsci.org/">Blog</b-nav-item>
          <b-nav-item-dropdown>
            <!-- Using button-content slot -->
            <template slot="button-content">
              <span alt="More" title="More">More</span>
            </template>
            <!--<b-dropdown-item
              :to="{ name: 'Mission' }"
              alt="Mission"
              title="Mission"
              >Mission</b-dropdown-item
            >-->
            <b-dropdown-item
              :to="{ name: 'Features' }"
              alt="Features"
              title="Features"
              >Features</b-dropdown-item
            >
            <!--<b-dropdown-item
              :to="{ name: 'Staff' }"
              alt="Team"
              title="Team"
              >Team</b-dropdown-item
            >-->
            <!--<b-dropdown-item
              :to="{ name: 'Partners' }"
              alt="Partners"
              title="Partners"
              >Partners</b-dropdown-item
            >-->
            <b-dropdown-item
              :to="{ name: 'Publications' }"
              alt="How to Cite"
              title="How to Cite"
              >How to Cite</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Developers' }"
              alt="Developers"
              title="Developers"
              >Developers</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'Connect' }"
              alt="Connect"
              title="Connect"
              >Connect</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- Commented out to fix redirects when user is clicking Login from any page: this is now handled by a Click method in BSNavbar.js --
          <b-nav-item :to="{ name: 'Login', query: { redirect: loginRedirect } }" v-if="!isAuthenticated" alt="Login" title="Login">Login</b-nav-item> -->

          <b-nav-item
            v-if="!isAuthenticated"
            alt="Sign Up"
            title="Sign Up"
            :to="{name: 'Register'}"
            >Sign Up</b-nav-item
          >

<!--          , query: { redirect: this.$route.fullPath }}-->
          <b-nav-item
            v-if="!isAuthenticated"
            alt="Login"
            title="Login"
            :to="{name: 'Login'}"
            >Login</b-nav-item
          >

          <b-nav-item-dropdown
            right
            text="My Projects"
            alt="My Projects"
            title="My Projects"
            v-if="isAuthenticated && userData"
          >
            <!-- <b-dropdown-item v-if="userData.projects.length == 0" active-class="no-active" alt="No projects yet" title="No projects yet">
              No projects yet
            </b-dropdown-item> -->

            <h6 class="dropdown-header">Published Projects</h6>
            <b-dropdown-item
              style="min-width: 300px"
              class="navbar-action-dropdown-item"
              v-if="projectsPublished.length > 0"
              v-for="projectItem in projectsPublished"
              :key="projectItem.projectId"
              :to="{ name: 'ProjectShow', params: { id: projectItem['projectUrl'] } }"
              active-class="no-active"
              :alt="projectItem.projectName"
              :title="projectItem.projectName"
            >
              <template v-if="projectItem.approved">
                {{ projectItem.projectName | shortText }}
              </template>
              <template v-else>
                {{ (projectItem.projectName + " (pending)") | shortText }}
              </template>
            </b-dropdown-item>

            <b-dropdown-item
              style="color:#22252C; margin-left:16px; margin-top:8px;"
              v-if="projectsPublished.length === 0"
              alt="No published projects"
              title="No published projects"
            >
              No published projects
            </b-dropdown-item>

            <div class="dropdown-divider"></div>

            <h6 class="dropdown-header">Draft Projects</h6>
            <b-dropdown-item
              style="min-width: 300px"
              class="navbar-action-dropdown-item"
              v-if="projectsDraft.length > 0"
              v-for="projectItem in projectsDraft"
              :key="projectItem.projectId"
              :to="{ name: 'ProjectShow', params: { id: projectItem['projectUrl'] } }"
              active-class="no-active"
              :alt="projectItem.projectName"
              :title="projectItem.projectName"
            >
              <template v-if="projectItem.approved">
                {{ projectItem.projectName | shortText }}
              </template>
              <template v-else>
                {{ (projectItem.projectName + " (pending)") | shortText }}
              </template>
            </b-dropdown-item>
            <div style="color:#22252C; margin-left:16px; margin-top:8px;"
              v-if="projectsDraft.length === 0"
              alt="No draft projects yet"
              title="No published projects yet"
            >
              No draft projects
            </div>

            <div class="dropdown-divider"></div>
            <b-dropdown-item
              style="min-width: 300px"
              class="navbar-action-dropdown-item"
              id="CreateProjectLink"
              title="Create New Project"
              alt="Create New Project"
              @click="onCreateClick"
            >
              <template> Create New Project </template>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            right
            alt="My Account"
            title="My Account"
            v-if="isAuthenticated && userData"
          >
            <!-- Using button-content slot -->
            <template slot="button-content">
              <span class="button-text pr-1">
                {{ userData.displayName }}
              </span>
              <v-img
                class="avatar"
                :src="
                  userData.picture && userData.picture.path
                    ? userData.picture.path
                    : '/assets/images/default_avatar.png'
                "
                alt="My Profile"
                title="My Profile"
              ></v-img>
            </template>
            <b-dropdown-item
              :to="{ name: 'UserProfile' }"
              alt="My Profile"
              title="My Profile"
              >My Profile</b-dropdown-item
            ><!-- style="margin-right:-10px;" -->
            <b-dropdown-item
              :to="{ name: 'Logout' }"
              alt="Logout"
              title="Logout"
              >Logout</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
      <div>
        <b-button
          class="btn-donate"
          active-class="no-active"
          :to="{ name: 'Donate' }"
          alt="Support CitSci.org"
          title="Support CitSci.org"
        >

          <span class="button-text">Donate</span>
          <font-awesome-icon icon="heart" />
        </b-button>
        <v-tooltip max-width="500" bottom>
          <a target="_blank" rel="noopener noreferrer"
          slot='activator'
          :href="`https://docs.google.com/forms/d/e/1FAIpQLSc_K0CvacZRItpra4_O5npwjZZTYzAPHPidUxr4rVrDUf9EVg/viewform?usp=pp_url&entry.630403450=https://citsci.org${$route.fullPath}`"
          class='btn btn-info mt-1 ml-2'>
            <span class='d-none d-xl-inline mr-2'>Help</span><span class='d-none d-lg-inline d-xl-none mr-2'>Help</span><i class='fa fa-question'></i>
          </a>
          <span>
            We have recently completed a massive upgrade to CitSci, rebuilding it from the ground up.
            Please be patient with us as we address your concerns and bugs. Use this form to report
            issues and someone on our team will take a look. We'll use your feedback to fix bugs,
            develop frequently asked questions, and prioritize new features.
          </span>
        </v-tooltip>
      </div>
    </div>
  </b-navbar>
</template>


<script>
import {mapGetters, mapActions} from 'vuex'
import { PROJECT_STATE_PUBLISHED, PROJECT_STATE_DRAFT } from '../../constants/project_states'

export default {
  name: 'BootstrapVueNavbar',

  data() {
    return {
      mainNavigationTabs: [
        //{ to: "/about", title: "About" },
        { to: { name: 'ProjectList' }, title: "Projects" },
        { to: "/solutions", title: "Solutions" },
        { to: "/services", title: "Services" }//,
        //{ to: "/blog", title: "Blog" }
      ],
      navigationDrawerTabs: [
        { to: "/about", title: "About", iconPrefix: 'far', iconName: "comment-dots" },
        { to: { name: 'ProjectList' }, title: "Projects", iconPrefix: 'fas', iconName: "cubes" },
        { to: "/solutions", title: "Solutions", iconPrefix: 'fab', iconName: "creative-commons-share" },
        { to: "/services", title: "Services", iconPrefix: 'far', iconName: "comment-dots" },
        { to: "/blog", title: "Blog", iconPrefix: 'fab', iconName: "blogger-b" },
        { to: "/protocols", title: "Protocols", iconPrefix: 'fas', iconName: "clipboard-list" },
        { to: "/partners", title: "Partners", iconPrefix: 'far', iconName: "handshake" }
      ],
      window: { width: 0 },
      navigationDrawer: false,
      userDetailsPopover: false,
      websiteLogoSmallDevices: false,
      mainNavigationBarRightSideDropdown: false,
      mainNavigationSearchField: true,
      mainNavigationBarHeight: 80,
      userProfileAvatarSize: 38,
      loginRedirect: null,
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'user/auth/isAuthenticated',
      userData: 'user/profile/sessionData',
      myMemberships: 'user/profile/membershipArray'
    }),
    projectsPublished: function(){
      return this.myMemberships.filter(function (item) {
        return item.projectState === PROJECT_STATE_PUBLISHED
      })
    },
    projectsDraft: function(){
      return this.myMemberships.filter(function (item) {
        return item.projectState === PROJECT_STATE_DRAFT
      })
    }
  },

  methods: {
    ...mapActions({
      retrieve: 'user/profile/fetchSession',
    }),

    /*
    onProjectNameClicked( routeName, data ) {
      // , hash: '#about'
      this.$router.push({name: routeName, params: { id: data['projectUrl'] } })
    },
    */
    handleResize() {
      this.window.width = window.innerWidth
      if (this.window.width >= 960) {
        this.userDetailsPopover = false
      } else {
        this.mainNavigationBarRightSideDropdown = false
      }
      if (this.window.width >= 1264) {
        this.navigationDrawer = false
      }
      if (this.window.width <= 600) {
        this.mainNavigationSearchField = false
        this.websiteLogoSmallDevices = true
        this.mainNavigationBarHeight=45
        this.userProfileAvatarSize=28
      } else {
        this.mainNavigationSearchField = true
        this.websiteLogoSmallDevices = false
        this.mainNavigationBarHeight=80
        this.userProfileAvatarSize=38
      }
    },

    onCreateClick() {
      // Check if user is verified
      if (JSON.parse(localStorage.getItem('sessionData')) !== null) {
        if (!JSON.parse(localStorage.getItem('sessionData')).isVerified) {
          this.$bvModal.show('not-verified-modal')
        } else {
          this.$router.push({name: 'ProjectCreate'})
        }
      } else {
        this.$router.push({name: 'Login', query: { redirect: '/projects/create' }});
      }
    },
  },

  created() {

    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    if (this.$route.fullPath.length > 0 && (this.$route.fullPath !== '/' && this.$route.fullPath.indexOf('404') === -1 && this.$route.fullPath.indexOf('logout') === -1)) {
      this.loginRedirect = { redirect: this.$route.fullPath }
    }
  },

  filters : {
    shortText : function ( value ) {
      if( value.trim().length < 32 ) {
        return value;
      } else {
        value = value.substring(0, 31) + "...";
        return value;
      }
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }

}

</script>
<style lang="scss" src="../../assets/styles/layout/BSNavbar.scss"></style>
