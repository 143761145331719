<template>
  <div class="position-relative">
    <GmapMap
      :center="{ lat: latitude, lng: longitude }"
      :zoom="5"
      map-type-id="terrain"
      class="locationMap"
    >
      <GmapMarker
        :key="index"
        v-if="locations.length > 0"
        v-for="(location, index) in locations"
        :position="{ lat: location.latitude, lng: location.longitude }"
        icon="/assets/images/orange-black-outline-dot.png"
        :clickable="true"
        @click="selectLocation(location)"
      />
      <GmapMarker
        v-if="projectHome"
        :position="projectHome"
        key="projectHome"
        icon="/assets/images/unselected-project-marker.png"
      />
      <GmapInfoWindow
        v-if="selectedLocation"
        :opened="selectedLocation"
        :position="{
          lat: selectedLocation.latitude,
          lng: selectedLocation.longitude
        }"
        :height="''"
        @closeclick="deselectLocation()"
      >
        <div class="locationDetails">
          <h3>{{ selectedLocation.name }}</h3>
          <div class="row mt-2">
            <div class="col-md-5 mt-2 mt-md-0 text-nowrap">
              <div>Latitude</div>
              <div>{{ selectedLocation.latitude }}</div>
            </div>
            <div class="col-md-5 ml-2 mt-2 mt-md-0 text-nowrap">
              <div>Longitude</div>
              <div>{{ selectedLocation.longitude }}</div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-5 mt-2 mt-md-0 text-nowrap">
              Observations: {{ selectedLocation.observationsTotal }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-5 mt-2 mt-md-0">
              <b-link
                class="btn btn-secondary text-nowrap"
                :href="
                  `https://www.google.com/maps/search/?api=1&query=${selectedLocation.latitude},${selectedLocation.longitude}`
                "
                target="_blank" rel="noopener noreferrer"
                >Directions</b-link>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <router-link
                :to="{ name: 'LocationShow', params: { id: selectedLocation.id } }"
                class="btn btn-primary text-nowrap"
                alt="View Location"
                title="View Location"
              >
                <font-awesome-icon icon="eye" /> View
              </router-link>
            </div>
          </div>
        </div>
      </GmapInfoWindow>
    </GmapMap>
    <span class="loadingMarkers my-3 py-2 px-3 bg-light"
      v-if="locations.length === 0">
      <span>Loading Project Locations</span>
      <i class="fa fa-spin fa-spinner ml-3"></i>
    </span>
  </div>
</template>

<script>
//import { locations } from 'store/modules/user/profile/getters';

export default {
  props: {
    projectHome: {
      type: Object,
      default: null
    },
    latitude: {
      type: Number,
      default: () => {
        return 42.0;
      }
    },
    longitude: {
      type: Number,
      default: () => {
        return -92.0;
      }
    },
    locations: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      selectedLocation: null
    };
  },
  methods: {
    selectLocation(location) {
      this.selectedLocation = location;
    },
    deselectLocation() {
      this.selectedLocation = null;
    }
  }
};
</script>

<style lang="">
.locationMap {
  width: 100%;
  height: 60vh;
}
.loadingMarkers {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.locationDetails {
  min-width:400px;
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>
