<template>
  <div>
    <div v-if="isPublicOrMember">
      <div class="card-body">
        <div class="row">
          <h3 class="mb-0 col-xs-3 col-md-3">
            Resources
          </h3>
          <div class="col-xs-5 col-md-6"></div>
          <div class="col-xs-4 col-md-3 float-right btn-group mt-2 p-0 pr-1">
            <div class="mr-1 label-text" style="float: left; margin-top: 6px;">
              <h6><b>View:</b></h6>
            </div>
            <b-button id="TableViewButton"
                      squared
                      size="sm"
                      class="btn btn-tertiary mr-1"
                      :pressed="!resourceShowCard"
                      @click="toggleView"
                      title="Table View">
              <i class="fa fa-table"></i>
              &nbsp;Table
            </b-button>
            <b-button id="CardViewButton"
                      squared
                      size="sm"
                      class="btn btn-tertiary ml-1"
                      :pressed="resourceShowCard"
                      @click="toggleView"
                      title="Card View">
              <i class="fa fa-th-large"></i>
              &nbsp;Card
            </b-button>
          </div>
        </div>
      </div>
        <ResourceTable class="mt-0"></ResourceTable>
    </div>
    <RequiresMember v-else page="Resources"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RequiresMember from "../tabpermission/requiresMember.vue";
import ResourceTable from "./resourceTable.vue";
import TagsPopular from "../resources/tagsPopular.vue";
import TagsFilter from "../resources/tagsFilter.vue";

export default {
  name: "Resources",
  components: {
    RequiresMember,
    ResourceTable,
    TagsFilter,
    TagsPopular,
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  destroyed() {},
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      resourceShowCard: "project/get/resourceShowCard",
      resourceItems: "project/get/resourceItems",
    }),
  },
  methods: {
    ...mapActions({
      setResourceShowCard: "project/get/setResourceShowCard",
    }),
    toggleView() {
      this.setResourceShowCard(!this.resourceShowCard)
    },
  }
};
</script>

<style></style>
