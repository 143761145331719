<template>
  <div class="mt-2 ml-2 mr-2">
    <div v-if="isManager">
      <b-row>
        <b-col class="col">
          <b-card class="mb-2" border-variant="light" bg-variant="light">
            <p>Welcome to the VERA Integration!<br>
              The Virtual Ecological/Epidemiological Research Assistant (VERA) web
              application enables users to construct conceptual models of systems, and
              run interactive simulations of these models. This allows users to explore
              ecological systems and perform "what if" experiments to either explain an
              existing system or attempt to predict the outcome of future changes. VERA
              uses large-scale domain knowledge from Smithsonian's Encyclopedia of Life
              (EOL) to help you with model construction.
            </p>
            <br>
            <h6>Read more about this integration
              <a href="https://vera.cc.gatech.edu/" target="_blank" rel="noopener noreferrer">here</a>
              <span><i class="fas fa-external-link-alt fa-xs"></i></span>
            </h6>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <RequiresManager v-else page="VERA Integration"></RequiresManager>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RequiresManager from "../tabpermission/requiresManager.vue";

export default {
  components: {RequiresManager},
  computed: {
    ...mapGetters({
      isManager: "project/get/isManager",
    })
  },
};
</script>

<style lang=""></style>
