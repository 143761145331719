<template>
  <div class="col-lg-4 mt-2 mb-2">
    <b-card class="card-resource">
      <b-card-body>
        <div v-if="isImage" class="row m-2"
             @click="(evt) => { handleView(resource) }" title="Click to View/Open">
          <b-img-lazy class="mx-0 card-b-img"
                 :src="resource.file.path" :alt="resource.file.name"
                 onerror="image404Handler()"
          />
        </div>
        <div v-else class="row m-2 card-icon"
             @click="(evt) => { handleView(resource) }" title="Click to View/Open">
          <i v-bind:class="mimeIcon"></i>
        </div>
        <div class="row justify-content-start p-0">
          <div class="col m-1 ml-2">
            <b>{{ resource.name }}</b>
          </div>
        </div>
        <b-card-footer class="p-0">
          <div class="row justify-content-between align-items-center m-0 ml-2">
            <div class="col-xs-9" v-if="isFile">
              <b-badge v-if="resource.file.mimeType" variant="secondary">{{
                  this.convertMimeType(resource.file.mimeType)
                }}
              </b-badge>
              <b-badge v-else variant="secondary">File</b-badge>
            </div>
            <div class="col-xs-9" v-else>
              <b-badge variant="secondary">Link</b-badge>
            </div>
            <div class="col-xs-3">
              <b-dropdown variant="outline" size="sm" no-caret>
                <template #button-content>
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </template>
                <b-dropdown-item @click="handleView(resource)">
                  <font-awesome-icon icon="eye"/>&nbsp;&nbsp;View
                </b-dropdown-item>
                <b-dropdown-item v-if="isManager"
                                 @click="handleEdit(resource)"><i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit
                </b-dropdown-item>
                <b-dropdown-item v-if="isManager"
                                 @click="handleDelete(resource)"><i class="fa fa-trash"></i>&nbsp;&nbsp;Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-card-footer>
        <div v-if="resource.tags" class="row justify-content-left m-0 ml-2 mt-1">
            <b-badge v-for="(tag, index) in resource.tags" :key="index"
              class="text-xsmall-bold btn btn-tertiary mr-1 mb-1"
              size="sm" pill
              data-toggle="tooltip"
              data-placement="top"
              title="Click to Filter Resources by this Tag"
              href="#"
              @click="handleTagClick(tag)">{{ tag }}
            </b-badge>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {convertMimeType} from "../../../../helpers/file_mime_type_helper";

export default {
  name: "ResourceCard",
  components: {},
  props: {
    resource: {
      type: Object,
      required: true,
    },
    handleView: {
      type: Function,
      required: true
    },
    handleEdit: {
      type: Function,
      required: true
    },
    handleDelete: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      isFile: false,
      isImage: false,
      mimeType: null,
      iconSize: 8,
    };
  },
  created() {
    this.isFile = this.resource.type === "file"
    if (this.isFile) {
      if (this.resource.file.mimeType) {
        this.mimeType = this.convertMimeType(this.resource.file.mimeType)
        this.isImage = this.mimeType === "image"
      }
    }
  },
  computed: {
    ...mapGetters({
      isManager: "project/get/isManager",
    }),
    mimeIcon() {
      if (!this.isFile) {
        return "fa fa-external-link fa-" + this.iconSize + "x"
      } else {
        if (this.mimeType) {
          return "fa " + this.convertMimeTypeIcon(this.resource.file.mimeType) + " fa-" + this.iconSize + "x"
        } else {
          return "fa fa-file fa-" + this.iconSize + "x"
        }
      }
    },
  },
  methods: {
    image404Handler() {
      return ''
    },
    handleTagClick(tag) {
      this.$emit('tagClick', tag)
    },
  }
};
</script>

<style lang="scss" scoped>

.card-resource {
  min-width: 375px;
  max-width: 375px;
  min-height: auto;
  max-height: 400px;
}

.card-body {
  min-height: auto;
  min-width: auto;
  padding: 0;

  .card-icon {
    cursor: pointer;
    justify-content: center;
  }

  .card-b-img {
    cursor: pointer;
    width: 100%;
    max-height: 114px;
    object-fit: contain; // cover
  }
}

.text-xsmall-bold {
  font-size: x-small;
  font-weight: bold;
}
</style>

