import show from './show'
import list from './list'
import organizationList from "./organizationList";
import user from "./user";

export default {
  namespaced: true,
  modules: {
    list,
    show,
    organizationList,
    user,
  }
}
