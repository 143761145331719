<template>
  <div>
    <div class="card-header" id="instructions">
      <h3>Instructions</h3>
      <ol>
        <li>
          Download the bulk invite CSV template. It has all the required
          columns<br/>
          <a class="btn btn-tertiary" href="/assets/file_template/BulkInviteMemberTemplate.csv" download>Download Bulk
            Invite template</a>
        </li>
        <li>
          Delete the sample data, add your own data, and save the file.
          <ul>
            <li>All fields are required.</li>
            <li>The 'Invite Message' column must be between 10 and 500 characters.</li>
          </ul>
        </li>
        <li class="font-weight-bold">Check "File Has Headers" if you used the template</li>
        <li>
          Click 'Choose File' and select your .csv file
        </li>
        <li>Click 'Scan file'</li>
        <li>
          Pick and match the Available Columns from the file to the Required
          Columns
        </li>
        <li>
          Verify that the Emails are accurate
        </li>
        <li>Click 'Send Invites'.</li>
      </ol>
    </div>
    <div class="card-body">
      <vue-csv-import :key="componentKey"
                      v-model="csv"
                      :map-fields="mappedFields"
                      autoMatchFields
                      autoMatchIgnoreCase
                      buttonClass="btn btn-primary"
                      inputClass="form-control-file"
                      tableClass="table table-sm table-bordered w-auto"
                      tableSelectClass="custom-select"
                      loadBtnText="Scan File">
        <template slot="error">
          File type is invalid
        </template>
        <template slot="thead">
          <tr>
            <th>Required Columns</th>
            <th>Available Columns</th>
          </tr>
        </template>
      </vue-csv-import>
    </div>
    <div class="card-header border-top pb-0" v-if="tableCSV" id="inviteStats">
      <div class="row">
        <div class="col-md-3">Total invites: {{ this.csv.length }}</div>
        <div class="col-md-3" v-if="this.tableValid.length">
          <i class="fa fa-check text-success mr-1"></i> Valid:
          {{ this.tableValid.length }}
        </div>
        <div class="col-md-3" v-if="this.tableInvalid.length > 0">
          <i class="fa fa-times-circle text-danger mr-1"></i> Missing
          information: {{ this.tableInvalid.length }}
        </div>
        <div class="col-md-3" v-if="this.numDuplicates > 0">
          <i class="fa fa-exclamation-triangle text-warning mr-1"></i>
          Duplicates: {{ this.numDuplicates }}
        </div>
      </div>
      <b-row align-h="between" no-gutters>
        <b-col sm="2" md="3" class="my-1">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
                        prev-text="Prev" next-text="Next" align="fill"></b-pagination>
        </b-col>
        <b-col sm="2" md="3" class="my-1">
          <b-form-group label="Per Page" label-align="right" label-cols-sm="6" label-cols-md="5"
                        label-for="perPageSelect" class="mb-0">
            <b-form-select v-model="perPage" id="perPageSelect" :options="pageOptions"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="tableCSV" id="validInviteTable">
      <b-table hover :items="tableCSV" :fields="addUserFields" id="bulkInvitesTable" ref="bulkInvitesTable" show-empty
               sort-icon-left :total-rows="totalRows" :per-page="perPage" :current-page="currentPage" responsive="md"
               outlined bordered small pagination>
      </b-table>
    </div>
    <div v-if="tableCSV" class="row card-body">
      <div class="col-md-3 my-2 small">
        Showing {{ fromItem }} to {{ toItem }} of {{ tableCSV.length }} invites
      </div>
      <div class="col-md-6 my-2">
        <div v-if="hasInvited" class="btn btn-block btn-light">
          <i class="mt-2 fa fa-check fa-1x mr-2 text-success"></i> Invites sent successfully...
        </div>
        <div v-else-if="isInviting" class="btn btn-block btn-secondary">
          <i class="fa fa-spin fa-spinner fa-1x mr-2"></i> Sending
          Invites...
        </div>
        <div v-else-if="this.tableValid.length === 0" class=""><i class="fa fa-times-circle mr-2 text-danger"></i>CSV
          did not contain new invites
        </div>
        <div id="btn-invite-bulk" class="btn btn-block btn-tertiary" @click="onInviteClick" v-else>
          <i class="fa fa-envelope mr-2"></i> Send Invites ({{ this.tableValid.length }})
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="btn btn-block btn-warning" title="Reset" @click="forceRender()">New Upload</div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  components: {},
  data() {
    return {
      componentKey: 0,
      csv: [],
      headers: true,
      hasHeaders: true,
      tableCSV: null,
      tableInvalid: null,
      tableValid: null,
      numDuplicates: 0,

      hasInvited: false,
      isInviting: false,

      collapse: true,
      mappedFields: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        inviteMessage: "Invite Message"
      },
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 0,
      tabIndex: 1,
      addUserFields: [{
        key: "firstName",
        label: "First Name",
        sortable: true,
        tdClass: "text-left",
        thClass: "text-center"
      },
        {
          key: "lastName",
          label: "Last Name",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-center"
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          key: "inviteMessage",
          label: "Note To Invitee:",
          sortable: false,
          tdClass: "text-left",
          thClass: "text-center"
        },
        {
          key: "invite",
          label: "Invite Exists?",
          sortable: false,
          tdClass: "text-left",
          thClass: "text-center"
        },
        {
          key: "member",
          label: "Already a Member?",
          sortable: false,
          tdClass: "text-left",
          thClass: "text-center"
        }
      ]
    };
  },
  created() {
  },
  watch: {
    csv(csv) {
      if (!csv) {
        return;
      }

      let arrDuplicates = [];
      this.tableCSV = [];
      this.tableInvalid = [];
      this.tableValid = [];

      // Check existing invites and members
      // - validate input
      csv.forEach(invite => {
        // Set all emails to lowercase
        invite.email = invite.email.toLowerCase().trim();
        // Validate csv data
        if (
          invite.firstName &&
          invite.lastName &&
          invite.email &&
          invite.inviteMessage
        ) {
          if (!arrDuplicates.find(val => val === invite.email)) {
            // Keep list of unique emails to prevent duplicates
            arrDuplicates.push(invite.email.toLowerCase().trim());
            this.tableCSV.push(invite);
            this.validateInvite(invite);
            this.validateMember(invite);
          }
        } else {
          // Invalid values
          this.tableInvalid.push(invite);
        }
      });

      this.numDuplicates = csv.length - arrDuplicates.length;
      this.perPage = 10;
      this.currentPage = 1;
      this.totalRows = this.tableCSV.length;

      // Instructions accordion is collapsed
      this.collapse = false;
    }
  },

  computed: {
    ...mapGetters({
      project: "project/get/project"
    }),
    fromItem() {
      return this.tableCSV.length > 0 ?
        (this.currentPage - 1) * this.perPage + 1 :
        0;
    },
    toItem() {
      return this.tableCSV.length < this.currentPage * this.perPage ?
        this.tableCSV.length :
        this.currentPage * this.perPage;
    }
  },

  methods: {
    ...mapActions({
      checkInviteExists: "project/invites/checkInviteExists",
      checkMemberExists: "project/invites/checkMemberExists",
      inviteProjectMember: "project/show/inviteProjectMember"
    }),
    toggle(value) {
      this.hasHeaders = !value;
    },
    validateInvite(invite) {
      this.checkInviteExists(invite).then(data => {
        let idx = this.tableCSV.findIndex(el => el.email === invite.email);
        if (data.length === 0) {
          this.tableCSV[idx].invite = "No";
        } else {
          this.tableCSV[idx].invite = "Yes";
        }
        this.setValidEmails();
        this.$refs.bulkInvitesTable.refresh();
      });
    },
    validateMember(invite) {
      this.checkMemberExists(invite).then(data => {
        let idx = this.tableCSV.findIndex(el => el.email === invite.email);
        if (data.length === 0) {
          this.tableCSV[idx].member = "No";
        } else {
          this.tableCSV[idx].member = "Yes";
        }
        this.setValidEmails();
        this.$refs.bulkInvitesTable.refresh();
      });
    },

    setValidEmails() {
      this.tableValid = [];
      this.tableCSV.forEach(val => {
        if (val.invite === "No" && val.member === "No") {
          this.tableValid.push(val);
        }
      });
    },

    onInviteClick() {

      // Perform invite here
      this.isInviting = true;
      let promises = [];

      this.tableValid.forEach(val => {
        // Adding project IRI to link the member to this project
        val.project = this.project['@id'];
        let promise = this.inviteProjectMember(val);
        promises.push(promise);
      });

      // wait till all request are complete <shows loading screen only one time>
      Promise.all(promises).then(values => {
        this.isInviting = false;
        if (values) {
          this.hasInvited = true;
        }
        // Close modal and refresh invites table
      });
    },

    resetInviteModal() {
      this.tableCSV = null;
      this.csv = null;
      this.collapse = true;
      this.currentPage = 1;
      this.perPage = 10;
      this.tabIndex = 1;
      this.isInviting = false;
      this.hasInvited = false;
    },

    forceRender() {
      // Reload the vue-csv-import plugin
      this.resetInviteModal();
      this.componentKey += 1;
    }
  }
};
</script>

<style lang="scss" scoped></style>
