<template>
  <b-modal id="invite-user-modal"
           size="xl"
           ref="inviteUserModal"
           no-close-on-backdrop
           body-class="p-0"
           centered>
    <template slot="modal-header" class="mx-auto">
      <h4 class="">Invite people to become members</h4>
    </template>
    <template slot="default">
      <div class="modal-body bg-light">
        <ul class="mb-0">
          <li>
            Invitee's will be sent an email with instructions.
            <ul>
              <li>
                These invite's will appear in the "Email Invites" section.
              </li>
              <li>
                CitSci Invites are active for 30 days until they expire. When they expire, the link will make them request to join the project, requiring a project managers approval.
              </li>
              <li>
                Invites are only good for the intended email address. If the link is shared, those users will have to request to join your project after registering.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    <b-tabs content-class="mx-auto"
            v-model="tabIndex"
            nav-class="navClass"
            card
            lazy>
      <b-tab title="Individual" active>
        <InviteUserSingle></InviteUserSingle>
      </b-tab>
      <b-tab no-body title="Bulk">
        <InviteUserBulk></InviteUserBulk>
      </b-tab>
    </b-tabs>
    </template>
    <template #modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="md" variant="primary" @click="onClose()">
        Close
      </b-button>
    </template>
    <!--    <b-tooltip target="invite-people-info" placement="right">-->
    <!--      An invite is sent via email to the Invitee.<br>-->
    <!--      If the Invitee is a registered CitSci User, they are immediately added as a member of the project.<br>-->
    <!--      Otherwise, the Invitee must first Register with CitSci and then are added as a member of the project.-->
    <!--    </b-tooltip>-->
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import InviteUserSingle from "./inviteUserSingle.vue";
import InviteUserBulk from "./inviteUserBulk.vue";

export default {
  components: {
    InviteUserSingle,
    InviteUserBulk
  },
  data() {
    return {
      tabIndex: 0,
      collapse: false,
    }
  },
  created() {
  },
  watch: {},
  computed: {},
  methods: {
    onClose() {
      // emit parent component refresh
      this.$emit('refresh-table');
      // close modal
      this.$refs.inviteUserModal.hide()
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
