import { mapGetters, mapActions } from 'vuex'
import VueLoader from '../../../components/layout/Loader'
import UserInviteTable from '../../../components/user/profile/invites/index.vue'
import UserObservationsTable from '../../../components/user/profile/observations/index.vue'
import UserLocationsTable from '../../../components/user/profile/locations/index.vue'

// TODO: separate this component in multiple subcomponents: general profile data, reset password, upload component, tabs section

export default {
  components: {
    VueLoader,
    UserInviteTable,
    UserObservationsTable,
    UserLocationsTable,
  },

  data () {
    return {
      updated: {},
      newPassword: {},
      //uploadFieldReady: true,
      //userCurrentImage: null,
      showUserGeneralData: false,
      recentActivity: 'coming soon',
      tabs: {
        'home': { index: 0, subTabs: {}, hash: '#home' },
        'my-invites': { index: 1, subTabs: {}, hash: '#my-invites' },
        'my-projects': { index: 2, subTabs: {}, hash: '#my-projects' },
        'my-observations': { index: 3, subTabs: {}, hash: '#my-observations' },
        'my-locations': {
          index: 3,
          subTabs: {
            'locations-data': { index: 0, hash: '#locations-data' },
            'locations-assigned': { index: 1, hash: '#locations-assigned' }
          },
          hash: '#my-locations'
        },
        // 'my-downloads': { index: 4, subTabs: {}, hash: '#my-downloads' },
        'my-preferences': { index: 5, subTabs: {}, hash: '#my-preferences' }
      },

      currentTab: 0,
      // dummy data for testing

      // My Observations tab
      myObservationsTableFields: [
        { key: 'date', label: 'Date', sortable: true, sortDirection: 'desc' },
        { key: 'project', label: 'Project', sortable: true, 'class': 'text-center' },
        { key: 'location', label: 'Location', sortable: true, 'class': 'text-center' },
        { key: 'lat', label: 'Lat', sortable: true, 'class': 'text-center' },
        { key: 'long', label: 'Long', sortable: true, 'class': 'text-center' },
      ],
      myObservationsCurrentPage: 1,
      myObservationsPerPage: 10,
      myObservationsTotalRows: 0,
      myObservationsSortBy: null,
      myObservationsSortDesc: false,
      myObservationsSortDirection: 'asc',

      // My locations tab
      myLocationsTableFields: [
        { key: 'name', label: 'Location Name', sortable: true, sortDirection: 'desc' },
        { key: 'latitude', label: 'Latitude', sortable: true, 'class': 'text-center' },
        { key: 'longitude', label: 'Longitude', sortable: true, 'class': 'text-center' },
        { key: 'observationsTotal', label: '# Observations', sortable: true, 'class': 'text-center' }
      ],
      myLocationsAssignedCurrentPage: 1,
      myLocationsAssignedPerPage: 10,
      myLocationsAssignedTotalRows: 0,
      myLocationsAssignedSortBy: null,
      myLocationsAssignedSortDesc: false,
      myLocationsAssignedSortDirection: 'asc',
      myLocationsWithDataCurrentPage: 1,
      myLocationsWithDataPerPage: 10,
      myLocationsWithDataTotalRows: 0,
      myLocationsWithDataSortBy: null,
      myLocationsWithDataSortDesc: false,
      myLocationsWithDataSortDirection: 'asc',


      observations: {},
      locationsWithData: [],
      locationsAssigned: [],
      preferences: {
        newsletter: false,
        scistarter: true,
        forums: true,
        observations: false
      }
    }
  },

  computed: {
    ...mapGetters({
      userCreated: 'user/register/created',
      error: 'user/profile/error',
      violations: 'user/profile/violations',
      isLoadingProjects: 'user/profile/isLoadingProjects',
      isLoadingStats: 'user/profile/isLoadingStats',
      isLoadingSession: 'user/profile/isLoadingSession',
      item: 'user/profile/sessionData',

      picturePath: 'user/profile/picturePath',
      pictureError: 'user/profile/pictureError',
      isPictureUploading: 'user/profile/isPictureUploading',
      isPictureRemoving: 'user/profile/isPictureRemoving',


      isEditingProfile: 'user/profile/isEditingProfile',
      resetPassword: 'user/profile/resetPassword',
      resetPasswordIsLoading: 'user/profile/resetPasswordIsLoading',
      passwordUpdated: 'user/profile/passwordUpdated',
      resetPasswordError: 'user/profile/resetPasswordError',
      resetPasswordViolations: 'user/profile/resetPasswordViolations',
      projectsList: 'user/profile/allProjects',
      retrievedProject: 'project/show/retrieved',
      stats: 'user/profile/stats',
    }),

    // Show verification modal when user registers
    isUserCreated: {
      get () {
        return this.userCreated !== null
      },
      set (newValue) {
        // Empty setter to prevent console error
      }
    },

    privacySetting: {
      get () {
        return String(this.item.privacySetting)
      },
      set (value) {
        this.resetUpdate()
        this.updated.privacySetting = parseInt(value)

        this.update({
          page: this.item['@id'],
          item: this.updated
        }).then(() => {
          // Remove the privacy value from the updated object
          delete this.updated.privacySetting
        })
      }
    },

    // Property to set and update the Receives Newsletter toggle
    receivesNewsletter: {
      get () {
        return this.item.receivesNewsletter
      },
      set (value) {
        this.resetUpdate()
        this.updated.receivesNewsletter = value

        this.update({
          page: this.item['@id'],
          item: this.updated
        }).then(() => {
          // Remove the newsletter value from the updated object
          delete this.updated.receivesNewsletter
        })
      }
    },

  },

  beforeDestroy () {
    this.resetUserUpdated()
    this.resetRegister()
  },

  created () {
    this.setCurrentActiveTab()
    this.getUserStats()
    this.resetUpdate()
  },

  methods: {
    ...mapActions({
      updateUserPicture: 'user/profile/updateUserPicture',
      removeUserPicture: 'user/profile/removeUserPicture',
      toggleEdit: 'user/profile/toggleEdit',
      update: 'user/profile/update',
      toggleResetPassword: 'user/profile/toggleResetPassword',
      updatePassword: 'user/profile/updatePassword',
      resetUserUpdated: 'user/profile/resetUserUpdated',
      resetRegister: 'user/register/reset',
      // getUserProjects: 'user/profile/getUserProjects',
      getAllUserProjects: 'user/profile/getAllUserProjects',
      getUserLocations: 'user/profile/getUserLocations',
      getUserObservations: 'user/profile/getUserObservations',
      getUserStats: 'user/profile/getUserStats',
    }),

    /**
     * Upload button handler
     */
    onPickFile () {
      this.$refs.pictureInput.click()
    },

    /**
     * Starts the picture upload process
     *
     * @param e
     * @returns {*|void}
     */
    onFilePicked (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.updateUserPicture(files[0]);
    },

    /**
     *
     */
    onShowEditProfileForm () {
      this.updated.email = this.item.email
      this.toggleEdit(true)
      this.toggleResetPassword(false)
    },

    /**
     *
     */
    onUpdateUserDetails () {
      this.$validator.validateAll().then(result => {
        if (result) {

          if (this.updated && typeof this.updated.email !== "undefined") {
            delete this.updated.email
          }

          this.update({
            page: this.item['@id'],
            item: this.updated
          }).then(() => {
            // this.showUserGeneralData = true
            // this.resetUpdate()
          })
        }
      })
    },

    /**
     *
     */
    onCancelUpdateUserProfile () {
      this.toggleEdit(false)
      this.resetUpdate()
    },

    /**
     *
     * @param key
     * @param resetPassword
     * @returns {*|boolean}
     */
    isInvalid (key, resetPassword = false) {
      let object = this.violations

      if (resetPassword) {
        object = this.resetPasswordViolations
      }

      return (object && Object.keys(object).length > 0 && object[key]) || this.errors.has(key)
    },

    /**
     *
     * @param key
     * @param resetPassword
     * @returns {*}
     */
    getError (key, resetPassword = false) {
      if (this.errors.has(key)) {
        return this.errors.first(key)
      }

      let object = this.violations

      if (resetPassword) {
        object = this.resetPasswordViolations
      }

      if (object && Object.keys(object).length > 0 && object[key]) {
        return object.key
      }
    },

    /**
     *
     */
    resetUpdate () {
      this.updated = {
        firstName: this.item.firstName,
        lastName: this.item.lastName,
        email: this.item.email,
        username: this.item.username,
      }
    },

    /**
     *
     */
    onShowResetPasswordForm () {
      this.toggleResetPassword(true)
      this.toggleEdit(false)
    },

    /**
     *
     */
    onResetUserPassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.updatePassword({
            userId: this.item.id,
            item: this.newPassword
          })
          //passwordUpdated=false;
        }
      })
    },
    /**
     *
     * @param ctx context from btable
     * @param callback
     */
    getMyObservations (ctx) {
      let urlParams = this.urlBuilder(ctx)
      return this.getUserObservations(urlParams).then((data)=>{
        this.myObservationsTotalRows = data['hydra:totalItems']
        this.myObservationsCurrentPage = ctx.currentPage || 1
        this.myObservationsSortBy = ctx.sortBy || ''
        // console.log('getMyObservations')
        return(data['hydra:member'].map((item)=>{
          const observation = item.observation;
          return{
            id: observation.id,
            date: observation.observedAt,
            project: observation.project,
            location: observation.location,
            lat: observation.location.latitude,
            long: observation.location.longitude,
          }
        }))
      })
    },

    /**
     *
     * @param ctx context from btable
     * @param callback
     */
    loadLocationsWithData(ctx, callback) {
      let urlParams = this.urlBuilder(ctx)
      return this.getUserLocations({ id: this.item.id, urlParams: urlParams, type: 'data' }).then((data) => {
        this.myLocationsWithDataTotalRows = data['hydra:totalItems']
        this.myLocationsWithDataCurrentPage = ctx.currentPage || 1
        this.myLocationsWithDataSortBy = ctx.sortBy || ''
        return(data['hydra:member'])
      })
    },

    /**
     *
     * @param ctx  context from btable
     * @param callback
     */
    loadLocationsAssigned(ctx, callback) {
      let urlParams = this.urlBuilder(ctx)
      return this.getUserLocations({ id: this.item.id, urlParams: urlParams, type: 'assigned' }).then((data) => {
        this.myLocationsAssignedTotalRows = data['hydra:totalItems']
        this.myLocationsAssignedCurrentPage = ctx.currentPage || 1
        this.myLocationsAssignedSortBy = ctx.sortBy || ''
        return(data['hydra:member'])
      })
    },

    /**
     * Since project's members are no longer auto-populated, this function will
     * return the new project.user data, if it exists.
     * @param project
     */
    getOwnerOfProject(project) {
      // user session data doesn't contain the user image info
      // let sessionData = JSON.parse(localStorage.getItem('sessionData'))
      let ownerUser = {}
      // project.members is no longer auto-populated
      // if (typeof project === 'undefined' || project === null || typeof project.members === 'undefined' || project.members === null) {
      //   return ownerUser
      // }
      //
      // project.members.forEach((item, index) => {
      //   if (item.isOwner) {
      //     ownerUser = item
      //   }
      // })
      if ( project && project.user )
        ownerUser = project.user
      return ownerUser
    },

    getProjectProfilePicture(project) {
      return (typeof project != 'undefined' && project != null && typeof project.picture != 'undefined'
        && project.picture != null && typeof project.picture.path != null ? project.picture.path : 'https://dev.citsci.org/UserUploads/6667/Media/Marlin_scared.sized.jpg?WebSiteID=7')
    },

    getProjectProfileBannerPicture(project) {
      return (typeof project != 'undefined' && project != null && typeof project.bannerPicture != 'undefined'
        && project.bannerPicture != null && typeof project.bannerPicture.path != null ? project.bannerPicture.path : '/assets/images/generic_img.png')
    },

    onTabChange(data) {
      return
      // causes scrolling to top of page
      /*for (let tab in this.tabs) {
        if (this.tabs[tab].index === data) {
          this.$router.replace({ name: 'UserProfile', params: this.$route.params, hash: ('#' + tab) })
          return
        }
      }*/
    },

    setCurrentActiveTab() {
      let currentHash = this.$route.hash.replace('#', '')

      for (let tab in this.tabs) {
        if (tab === currentHash) {
          this.currentTab = this.tabs[tab].index
          return
        }
      }
    },

    onClickVerify() {
      // This is the recommended way to call a modal instead of root.emit
      // https://bootstrap-vue.org/docs/components/modal#emitting-events-on-root
      this.$bvModal.show('not-verified-modal')
    },

    onCreateClick() {
      // Check if user is verified
      if (JSON.parse(localStorage.getItem('sessionData')) !== null) {
        if (!JSON.parse(localStorage.getItem('sessionData')).isVerified) {
          this.$bvModal.show('not-verified-modal')
        } else {
          this.$router.push({name: 'ProjectCreate'})
        }
      }
    },

    onJoinClick() {
      // Check if user is verified
      if (JSON.parse(localStorage.getItem('sessionData')) !== null) {
        if (!JSON.parse(localStorage.getItem('sessionData')).isVerified) {
          this.$bvModal.show('not-verified-modal')
        } else {
          this.$router.push({name: 'ProjectList'})
        }
      }
    }

  },
}
