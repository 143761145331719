<template>
  <div class="mt-2 ml-2 mr-2">
    <div v-if="isManager">
      <b-row>
        <b-col class="col">
          <b-card class="mb-2" border-variant="light" bg-variant="light">
            <p> Welcome to the Zooniverse Integration!<br>
              Connect your project with a corollary project at <a href="https://www.zooniverse.org/" target="_blank" rel="noopener noreferrer">Zooniverse</a>
              <span><i class="fas fa-external-link-alt fa-xs"></i></span>
              <br>
              Share photos from your CitSci Observations and have Zooniverse volunteers help classify your photos.</p>
            <br>
            <h6>Read more about this integration which uses the Panoptes API
              <a href="https://zooniverse.github.io/panoptes/?http#introduction" target="_blank" rel="noopener noreferrer">here</a>
              <span><i class="fas fa-external-link-alt fa-xs"></i></span>
            </h6>
          </b-card>
        </b-col>
      </b-row>
      <div v-if="projectVuex && projectVuex.isZooniverse" class="card-body">
        <div class="mb-4">
          <b-button
              class="btn btn-sm dropdown-toggle mb-2"
              @click="collapse = !collapse"
              variant="secondary"
              h-align="center"
          >
            <font-awesome-icon icon="info"/>
            &emsp;Instructions
          </b-button>
          <div v-show="!collapse">
            <div>
              1. Enter a Zooniverse Username. (this Username will serve as this CitSci Project's Collaborator)
            </div>
            <div class="mb-2">
              2. Click on 'Look Up Username' to verify and set the Zooniverse Username.
            </div>
            <div>
              3. Next, Click on 'Create Zooniverse Project'.
            </div>
            <div class="mb-2">
              4. A Zooniverse Project will be created with this CitSci Project's information.
            </div>
            <div>
              5. Next, Pick a Project Datasheet.
            </div>
            <div class="mb-2">
              6. Click 'Transfer Images' to send the images over to the Zooniverse Project.
            </div>
            <div>
              7. (Optional) Click 'View Zooniverse Project' to view the Project over at Zooniverse and the images transferred under Subject Sets.
            </div>
          </div>
        </div>

        <div class="row mb-222">
          <div class="col-6">
            <ZooniverseUsername></ZooniverseUsername>
          </div>
          <div class="col-6">
            <ZooniverseProject></ZooniverseProject>
          </div>
        </div>
        <div class="row justify-center mt-4">
          <div class="card col-8 p-0">
            <DatasheetSelection></DatasheetSelection>
          </div>
        </div>
      </div>
      <div v-else>
        <b-card class="col-6 mb-2" border-variant="light" bg-variant="light">
          Zooniverse Integration has not been Enabled.<br>
          Enable it under Edit Project.
        </b-card>
      </div>
    </div>
    <RequiresManager v-else page="Zooniverse Integration"></RequiresManager>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RequiresManager from "../tabpermission/requiresManager.vue";
import ZooniverseUsername from './username.vue'
import ZooniverseProject from "./project.vue";
import DatasheetSelection from "./datasheetSelection.vue";

export default {
  name: "Zooniverse",
  props: {},
  components: {
    RequiresManager,
    ZooniverseUsername,
    ZooniverseProject,
    DatasheetSelection,
  },
  data() {
    return {
      collapse: true,
    };
  },
  created() { },
  computed: {
    ...mapGetters({
      isManager: "project/get/isManager",
      isLoading: "project/get/isLoading",
      projectVuex: "project/get/project",

      userVuex: "user/profile/sessionData",
      history: "project/get/zooniverse/historyForDatasheet",
      isLoadingUser: "project/get/zooniverse/isLoadingUser",
      isLoadingProjectId: "project/get/zooniverse/isLoadingProjectId",
      isLoadingDatasheets: "project/get/zooniverse/isLoadingDatasheets",
      messageLoadingUser: "project/get/zooniverse/messageLoadingUser",
      messageLoadingProject: "project/get/zooniverse/messageLoadingProject",
      messageLoadingDatasheets:
          "project/get/zooniverse/messageLoadingDatasheets"
    }),
  },

  methods: {
    ...mapActions({
    }),
  }
};
</script>

<style lang="scss" scoped></style>
