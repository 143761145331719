<template>
  <highcharts v-if="chartData.length > 0" :options="chartOptions" ref="pie"></highcharts>
  <span v-else>No Observations</span>
</template>

<script>
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import fullscreen from "highcharts/modules/full-screen";

exporting(Highcharts);
offlineExporting(Highcharts);
fullscreen(Highcharts);

export default {
  name: 'PieChart',
  props: {
    sampleSize: {
      type: Number,
      default: () => {
        return 0
      },
      required: true,
    },
    chartData: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
    chartTitle: {
      type: String,
      default: () => {
        return ''
      }
    },
  },
  data() {
    return {
      localChartTitle: this.chartTitle,
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          events: {
            fullscreenOpen: this.addTitle,
            fullscreenClose: this.removeTitle,
            beforePrint: this.addTitle,
            afterPrint: this.removeTitle,
          }
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.key + ': </b>' + this.y + '% (' + this.point.num + ')'
          }
        },
        title: {
          text: null,
          align: "center",
          style: {
            fontSize: "1.4em",
            fontWeight: "bold",
          }
        },
        subtitle: {
          text: "Sample Size: " + this.sampleSize,
          align: "left",
          style: {
            fontSize: "1.0em",
            fontWeight: "regular",
          }
        },
        credits: {
          enabled: true,
          text: 'Powered by CitSci via HighCharts',
          href: ''
        },
        series: [{data: this.chartData}],
        // Set title before downloading chart
        exporting: {chartOptions: {title: {text: this.chartTitle || this.localChartTitle,}}},
      }
    }
  },
  watch: {
    chartData(newVal) {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        series: [{data: newVal}]
      })
    },
    sampleSize(newVal) {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        subtitle: {
          text: "Sample Size: " + newVal,
        }
      })
    },
    chartTitle(newVal) {
      this.localChartTitle = newVal
    },
  },
  methods: {
    addTitle() {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        title: {
          text: this.chartTitle || this.localChartTitle,
        },
      })
    },
    removeTitle() {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        title: {
          text: null,
        },
      })
    },
  },
}
</script>
