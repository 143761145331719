<template>
  <div class="projectHeader" :style="headerStyle">
    <div class="container pb-1 h-100 d-flex flex-column projectHeader">
      <div class="flex-fill">
        <div class="row">
          <div class="col-md-12 mt-1">
            <h1 v-if="isLoading" class="projectName p-3 mb-3 mt-0">
              Loading Project...
            </h1>
            <i class="fa fa-3x fa-spin fa-spinner ml-4" v-if="isLoading"></i>
            <h1 v-else class="text-left p-3 mb-0 mt-2 w-100">
                    <span class="d-none d-lg-inline mb-1 align-text-bottom float-right pt-1"
                          v-if="project && project.picture && !isLoading">
                      <img class='projectPicture rounded-circle' :src="project.picture.path" alt="Project Logo"/>
                    </span>
              <span class="col col-md-10 mx-auto float-left"><span class="projectName">{{ name }}</span></span>
            </h1>
          </div>
        </div>
      </div>
      <div class="flex-none mt-2">
        <div class="row h-100 align-items-end">
          <div class="col-6 col-lg-2 mb-2">
            <LeftHeader v-if="project"></LeftHeader>
          </div>
          <div class="col-lg-8 order-3 order-lg-2 mb-2">
            <Stats v-if="!isLoading"></Stats>
          </div>
          <div class="col-6 col-lg-2 order-2 order-lg-3 mb-2">
            <RightHeader v-if="project"></RightHeader>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import LeftHeader from './LeftHeader'
import Stats from './Stats.vue'
import RightHeader from './RightHeader'

export default {
  components: {
    LeftHeader,
    Stats,
    RightHeader,
  },
  computed: {
    ...mapGetters({
      project: 'project/get/project',
      projectId: 'project/get/id',
      isLoading: 'project/get/isLoading',
    }),
    name() {
      return this.project ? this.project.name : this.$route.params.id;
    },
    headerStyle() {
      return {
        backgroundImage: `url(${(this.project && this.project.bannerPicture && this.project.bannerPicture.path)
          ? this.project.bannerPicture.path
          : '/assets/images/header/default-project-header-banner.png'
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        backgroundColor: 'grey',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.projectHeader {
  min-height: 320px;
}

.projectName {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  display: inline;
  float: left;
  font-size: 3.5rem !important;
  padding-top: 12px;
  padding-left: 6px;
  padding-right: 6px;
  // width:90%;
}

.projectPicture {
  width: 120px;
  height: 120px;
  max-height: 120px;
  max-width: 120px;
  border: solid 1px white;
}

.projectDescription {
  color: #F7F7F7;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  font-size: 1.1rem !important;
}

</style>
