<template>
  <div class="OrganizationAffiliations">
    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <h5>
        <span class="h4-icon"><i class="fa fa-compress fa-lg"></i></span>&nbsp;&nbsp;
        Pending Project Affiliations:
      </h5>
      <b-list-group v-if="pending && Object.keys(pending).length > 0">
        <b-list-group-item v-for="request in pending" :key="request.id">
          <b-row>
            <b-col md="12">
              <b>{{ request.project.name }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-btn class="primary" size="sm" @click="handleRequest(request, true)">Approve</b-btn>&nbsp;
              <b-btn variant="danger" size="sm" @click="handleRequest(request, false)">Deny</b-btn>&nbsp;
<!--              <b-btn variant="info" size="sm">Email</b-btn>-->
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <label v-else>No Pending Requests</label>
    </b-container>

    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <h5>
        <span class="h4-icon"><i class="fa fa-handshake-o fa-lg"></i></span>&nbsp;
        Approved Project Affiliations:
      </h5>
      <b-list-group v-if="approved && Object.keys(approved).length > 0">
        <b-list-group-item
          v-for="affiliation in approved" :key="affiliation.id">
          <b-row>
            <b-col md="12">
              <router-link class="font-weight-bold" title="Visit the Project Page (opens in a new tab)"
                :to="{ name: 'ProjectShow', params: { id: affiliation.project.urlField }}" target="_blank" rel="noopener noreferrer">
                <b>{{ affiliation.project.name }}</b>
              </router-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
<!--              <b-btn variant="secondary" size="sm" :to="{ name: 'ProjectShow', params: { id: affiliation.project.urlField } }">To Project</b-btn>-->
<!--              <b-btn variant="danger" size="sm" onclick="removeAffiliation(affiliation.id)">Remove</b-btn>&nbsp;-->
<!--              <b-btn variant="info" size="sm">Email</b-btn>-->
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-container>

    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <h5>
        <span class="h4-icon"><i class="fa fa-ban fa-lg"></i></span>&nbsp;&nbsp;
        Denied Project Affiliations:
      </h5>
      <b-list-group v-if="denied && Object.keys(denied).length > 0">
        <b-list-group-item v-for="request in denied" :key="request.id">
          <b-row>
            <b-col md="12">
              <router-link class="font-weight-bold" title="Visit the Project Page (opens in a new tab)"
                           :to="{ name: 'ProjectShow', params: { id: request.project.urlField }}"
                           target="_blank" rel="noopener noreferrer">
                <b>{{ request.project.name }}</b>
              </router-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <label v-else>No Requests Denied</label>
    </b-container>


<!--    <b-container class="mt-0 mb-0">-->
<!--      <h5>-->
<!--        <span class="h4-icon"><i class="fa fa-id-card fa-lg"></i></span>&nbsp;&nbsp;-->
<!--        Invite Projects:-->
<!--      </h5>-->
<!--    </b-container>-->

<!--    <b-container class="mt-0 mb-0">-->
<!--      <h5>-->
<!--        <span class="h4-icon"><i class="fa fa-id-card fa-lg"></i></span>&nbsp;&nbsp;-->
<!--        Invite Hub Manager:-->
<!--      </h5>-->
<!--    </b-container>-->
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: 'Organizationaffiliations',
  data() {
    return {
    }
  },
  created() {
    if (this.organizationVuex && !this.allOrgProjects) {
      this.orgProjects('organizations')
    }
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      allOrgProjects: "organization_projects/get/orgProjects",
      isLoading: "organization_projects/get/isLoading",
      pending: "organization_projects/get/pending",
      approved: "organization_projects/get/approved",
      denied: "organization_projects/get/denied",
    })
  },
  methods: {
    ...mapActions({
      orgProjects: "organization_projects/get/get",
      approveDenyAffiliation: "organization_projects/get/approveDenyAffiliation"
    }),
    handleRequest(request, bApproveDeny) {
      this.approveDenyAffiliation({ id: request.id, approveDeny: bApproveDeny })
    },
    removeAffiliation(id) {

    },
    contactProjectManager(id) {

    },
  }
};
</script>

<style>

</style>
