import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    observations: null,
    error: null,

    // table state
    observationPage: 1,
    observationItems: 10,
    observationTotal: 0,
    observationSearch: "",
    observationSortBy: "observedAt",
    observationSortDirection: "desc",
    observationSortDesc: true,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  observations(state) {
    return state.observations
  },
  error(state) {
    return state.error
  },

  observationPage(state) {
    return state && state.observationPage
  },
  observationItems(state) {
    return state && state.observationItems
  },
  observationSearch(state) {
    return state && state.observationSearch
  },
  observationTotal(state) {
    return state && state.observationTotal
  },
  observationSortBy(state) {
    return state && state.observationSortBy
  },
  observationSortDirection(state) {
    return state && state.observationSortDirection
  },
  observationSortDesc(state) {
    return state && state.observationSortDesc
  },
}

const actions = {
  /**
   * @param commit
   * @param dispatch
   * @param rootState
   * @param data
   * @returns {Promise<T | never> | * | *}
   */
  get({commit, dispatch, rootState}, data) {
    commit(types.SET_USER_OBSERVATIONS_LOADING);
    return fetch(rootState.user.profile.sessionData['@id'] + '/observations' + data.urlParams, {
      method: 'GET',
    })
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_USER_OBSERVATIONS, data)
        return data
      })
      .catch((error) => {
        commit(types.SET_USER_OBSERVATIONS_ERROR, error)
      })
  },
  reset({commit, state}) {
    commit(types.SET_USER_OBSERVATIONS_LOADING);
    commit(types.RESET)
  },

  setObservationTablePage({commit}, page) {
    commit(types.SET_OBS_PAGE, page)
  },
  setObservationTableItems({commit}, items) {
    commit(types.SET_OBS_ITEMS, items)
  },
  setObservationTableTotal({commit}, total) {
    commit(types.SET_OBS_TOTAL, total)
  },
  setObservationTableSearch({commit}, search) {
    commit(types.SET_OBS_SEARCH, search)
  },
  setObservationTableSortBy({commit}, sortBy) {
    commit(types.SET_OBS_SORT_BY, sortBy)
  },
  setObservationTableSortDirection({commit}, sortDirection) {
    commit(types.SET_OBS_SORT_DIRECTION, sortDirection)
  },
  setObservationTableSortDesc({commit}, sortDesc) {
    commit(types.SET_OBS_SORT_DESC, sortDesc)
  },
}

const types = {
  SET_USER_OBSERVATIONS_LOADING: 'SET_USER_OBSERVATIONS_LOADING',
  SET_USER_OBSERVATIONS: 'SET_USER_OBSERVATIONS',
  SET_USER_OBSERVATIONS_ERROR: 'SET_USER_OBSERVATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',

  SET_OBS_PAGE: 'SET_OBS_PAGE',
  SET_OBS_ITEMS: 'SET_OBS_ITEMS',
  SET_OBS_TOTAL: 'SET_OBS_TOTAL',
  SET_OBS_SEARCH: 'SET_OBS_SEARCH',
  SET_OBS_SORT_BY: 'SET_OBS_SORT_BY',
  SET_OBS_SORT_DIRECTION: 'SET_OBS_SORT_DIRECTION',
  SET_OBS_SORT_DESC: 'SET_OBS_SORT_DESC',
}

const mutations = {
  [types.SET_USER_OBSERVATIONS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_USER_OBSERVATIONS](state, data) {
    state.observations = data
    state.isLoading = false
  },
  [types.SET_USER_OBSERVATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },

  [types.SET_OBS_PAGE](state, page) {
    state.observationPage = page;
  },
  [types.SET_OBS_ITEMS](state, items) {
    state.observationItems = items;
  },
  [types.SET_OBS_TOTAL](state, total) {
    state.observationTotal = total;
  },
  [types.SET_OBS_SEARCH](state, search) {
    state.observationSearch = search;
  },
  [types.SET_OBS_SORT_BY](state, sortBy) {
    state.observationSortBy = sortBy;
  },
  [types.SET_OBS_SORT_DIRECTION](state, sortDirection) {
    state.observationSortDirection = sortDirection;
  },
  [types.SET_OBS_SORT_DESC](state, sortDesc) {
    state.observationSortDesc = sortDesc;
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
