import fetch from '../../../../utils/fetch'
import Vue from 'vue'
import SubmissionError from "../../../../error/SubmissionError";

const getInitialState = () => {
  return {
    isLoading: false,
    tokenBearer: localStorage.getItem('tokenBearer'),
    tokenBearerExpiry: localStorage.getItem('tokenBearerExpiry'),
    tokenRefresh: localStorage.getItem('tokenRefresh'),
    tokenRefreshExpiry: localStorage.getItem('tokenRefreshExpiry'),
    userId: localStorage.getItem('userId'),
    refreshTimeoutId: null,
    error: null,
    violations: null,
    passwordReset: false,
    isLogout: null,
  }
}

const state = getInitialState();

const actions = {

  authenticate({dispatch, commit}, params) {
    commit(types.RESET_AUTH_ERROR)
    commit(types.SET_AUTH_LOADING)
    return fetch('/login', {method: 'POST', body: JSON.stringify(params.creds)})
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        commit(types.SET_AUTH_TOKENS, data)
        commit(types.SET_LOGOUT, false)
        // Call method to set timeout to refresh
        dispatch('checkTokenExpiry', null, {root: false});
        dispatch('user/profile/fetchSession', null, {root: true});
      })
      .catch((error) => {
        if (error instanceof SubmissionError) {
          commit(types.SET_AUTH_VIOLATIONS, error.errors)
          commit(types.SET_AUTH_ERROR, error.errors._error)
        } else {
          commit(types.SET_AUTH_ERROR, error.message)
          commit(types.SET_AUTH_PASSWORD_RESET, error.resetPassword)
        }
      })
  },

  renewTokens({commit, dispatch}) {
    fetch('/token/refresh',
      {
        method: 'POST',
        body: JSON.stringify({refresh_token: state.tokenRefresh})
      })
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_AUTH_TOKENS, data)
        commit(types.SET_REFRESH_TIMEOUT, null)
        dispatch("checkTokenExpiry", null, {root: false})
      })
      .catch((e) => {
        commit(types.SET_AUTH_ERROR, e.message)
        dispatch("triggerRouterLogout", null, {root: false})
      })
  },

  clearTabTimeout({commit, state}) {
    // clear the timeout when the tab is idle
    clearTimeout(state.refreshTimeoutId)
    commit(types.SET_REFRESH_TIMEOUT, null)
  },

  triggerRouterLogout({commit}) {
    commit(types.SET_LOGOUT, true)
  },

  logout({dispatch, commit}) {
    dispatch('clearTabTimeout', null, {root: false});
    commit(types.RESET)
  },

  checkTokenExpiry({commit, dispatch, state}) {
    // No token, nothing to do
    if (!state.tokenBearer) return

    // Check refresh token is expired, then check access token is expired
    let now = Math.ceil(Date.now() / 1000)
    if (state.tokenRefreshExpiry < now) {
      // Refresh token is expired, logout
      dispatch("triggerRouterLogout", null, {root: false})
    } else {
      if (state.tokenBearerExpiry < now) {
        // Renew the tokens
        dispatch("renewTokens", null, {root: false})
      } else {
        // Tokens are valid, auto-refresh is checked
        if (state.refreshTimeoutId === null) {
          // Set timeout to auto-renew tokens
          let buffer = 1 * 60 * 1000 // 1 minute before expiry
          let tokenExpiry = (state.tokenBearerExpiry - now) * 1000
          let id = setTimeout(function ({commit}) {
            dispatch("renewTokens", null, {root: false})
          }, (tokenExpiry - buffer), {commit})
          commit(types.SET_REFRESH_TIMEOUT, id)
          // console.log('new timeout in : ', id, tokenExpiry - buffer)
        } else {
          // Nothing to do here :)
        }
      }
    }
  },
}

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  isAuthenticated(state) {
    return state.tokenBearer !== null
  },
  error(state) {
    return state.error
  },
  violations(state) {
    return state.violations
  },
  passwordReset(state) {
    return state.passwordReset
  },
  isLogout(state) {
    return state.isLogout
  },
}

const types = {
  SET_AUTH_LOADING: 'SET_AUTH_LOADING',
  SET_AUTH_TOKENS: 'SET_AUTH_TOKENS',
  SET_AUTH_ERROR: 'SET_AUTH_ERROR',
  SET_AUTH_VIOLATIONS: 'SET_AUTH_VIOLATIONS',
  SET_AUTH_PASSWORD_RESET: 'SET_AUTH_PASSWORD_RESET',
  RESET_AUTH_ERROR: 'RESET_AUTH_ERROR',
  SET_REFRESH_TIMEOUT: 'SET_REFRESH_TIMEOUT',
  SET_LOGOUT: 'SET_LOGOUT',
  RESET: 'RESET',
}

const mutations = {

  [types.SET_AUTH_LOADING](state) {
    state.isLoading = true;
  },

  [types.SET_AUTH_TOKENS](state, tokens) {
    localStorage.setItem('tokenBearer', tokens['token'])
    state.tokenBearer =  localStorage.getItem('tokenBearer')
    localStorage.setItem('tokenBearerExpiry', Vue.$jwt.decode().exp)
    state.tokenBearerExpiry = localStorage.getItem('tokenBearerExpiry')
    localStorage.setItem('tokenRefresh', tokens['refresh_token']);
    state.tokenRefresh = localStorage.getItem('tokenRefresh')
    localStorage.setItem('tokenRefreshExpiry', tokens['refresh_token_expiration'])
    state.tokenRefreshExpiry =  localStorage.getItem('tokenRefreshExpiry')
    localStorage.setItem('userId', Vue.$jwt.decode()['id'])
    state.userId = localStorage.getItem('userId')
    state.isLoading = false
  },

  [types.SET_AUTH_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },

  [types.SET_AUTH_VIOLATIONS](state, violations) {
    state.violations = violations;
    state.isLoading = false;
  },

  [types.SET_AUTH_PASSWORD_RESET](state, passwordReset) {
    state.passwordReset = passwordReset;
    state.isLoading = false;
  },

  [types.RESET_AUTH_ERROR](state) {
    state.error = null;
    state.violations = null;
    state.isLoading = false;
  },

  [types.SET_REFRESH_TIMEOUT](state, id) {
    state.refreshTimeoutId = id
  },

  [types.SET_LOGOUT](state, status) {
    state.isLogout = status
  },

  [types.RESET](state) {
    localStorage.clear();
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
}
