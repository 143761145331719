<template>
  <b-container>
    <div class="donate col-9 col-md-auto">
      <h1>Terms & Code of Conduct</h1>

      <p class="p-down"><i>CitSci Terms & Code of Conduct as of February 22, 2024</i></p>

      <h2>Terms</h2>

      <p>By accessing the CitSci website at
        <b-link href="https://citsci.org">
          https://citsci.org
        </b-link><i class="fas fa-external-link-alt fa-xs"></i>
        , you are agreeing to be bound by these terms
        of service, all applicable laws and regulations, and agree that you are
        responsible for compliance with any applicable local laws. If you do not
        agree with any of these terms, you are prohibited from using or accessing
        this site. The materials contained in this website are protected by applicable
        copyright and trademark law.
      </p>

      <h2>Code of Conduct</h2>

      <p>CitSci.org is a non-profit, community-based organization encouraging
        volunteer participation in the measurement and reporting of matters of
        concern to communities. We encourage and support initiatives aimed to
        improve livelihoods, the quality of life of community members, and the
        overall wellbeing of ecosystems of which human life depends on for well
        being. We expect respectful, thoughtful, equitable, inclusive, and cordial
        behavior by those participating in initiatives and projects started on CitSci
        and reserve the right to revoke access to those not following decent behavior
        that respects others.</p>

      <div id="data" ref="data">
        <h2>Data Use</h2>

        <p>CitSci is a website and organization developed under Colorado State
          University, a public educational institution. Our mission is to provide
          comprehensive support for citizen science and community-based monitoring
          efforts worldwide. Unless otherwise noted (see below), all CitSci content
          and data are released under a
          <b-link href="https://creativecommons.org/licenses/by/3.0/deed.en_US"
                  target="_blank" rel="noopener noreferrer">Creative Commons CC BY 3.0 License
          </b-link><i class="fas fa-external-link-alt fa-xs"></i>
          .
        </p>

        <div class="ml-4 mt-4 mb-4 mr-4">
          <h3>The Data We Collect</h3>
          <p>Projects created using CitSci.org collect diverse heterogeneous
            information that they themselves decide is important to them. These data
            may contain integers, decimal data, textual data, categorical data,
            photographs, and date/time information about any phenomenon of interest
            and relevance to our projects.</p>

          <h3>Purpose of the Data</h3>
          <p>The data gathered by volunteer observers for 'public projects' through
            the CitSci platform is freely available to governments, academic institutions
            and the private sector as well as participants and the general public for
            the purposes of promoting learning, enhancing scientific knowledge, and
            protecting life and property.</p>

          <h3>How Data are Shared</h3>
          <p>CitSci.org data from 'public projects' are made available to the public
            via the website (https://citsci.org). Data from 'private projects' are not
            made available via the website to non-members and are only accessible by
            project members.</p>

          <h3>How Long Data are Stored</h3>
          <p>Data are stored on the CitSci.org servers indefinitely unless otherwise
            requested by the project managers. CitSci reserves the right to archive
            or otherwise remove data to store offline should it be deemed necessary.
            CitSci also reserves the right to delete false or malicious data at anytime.</p>

          <h3>Data License and Attribution</h3>
          <p>We kindly ask that those using CitSci data please
            <b-link to="cite"> acknowledge and cite CitSci</b-link>
            and the original source(s) of the
            data. These sources may include CitSci projects and/or participants. Unless otherwise
            noted (e.g., private projects), all CitSci content and data are released
            under a
            <b-link href="https://creativecommons.org/licenses/by/3.0/deed.en_US"
                    target="_blank" rel="noopener noreferrer">Creative Commons CC BY 3.0 License
            </b-link><i class="fas fa-external-link-alt fa-xs"></i>
            .
          </p>
        </div>
      </div>

      <h2>Disclaimer</h2>

      <p>CitSci.org data are provided "as is", and in no event shall the providers
        be liable for any damage or loss due to missing data or mis-interpretation
        of its content. Further, CitSci does not warrant or make any representations
        concerning the accuracy, likely results, or reliability of the use of the
        materials on its website or otherwise relating to such materials or on any
        sites linked to this site.</p>

      <h2>Limitations</h2>

      <p>In no event shall CitSci or Colorado State University be liable for any
        damages (including, without limitation, damages for loss of data or profit,
        or due to business interruption) arising out of the use or inability to use
        the materials on the CitSci website, even if CitSci or a CitSci authorized
        representative has been notified orally or in writing of the possibility
        of such damage. Because some jurisdictions do not allow limitations on
        implied warranties, or limitations of liability for consequential or
        incidental damages, these limitations may not apply to you.</p>

      <h2>Accuracy</h2>
      <p>The content, materials, and data on the CitSci platform may include
        technical, typographical, or photographic errors. CitSci does not warrant
        that any of the materials on its website are accurate, complete or current.
        CitSci may make changes to the content, materials, and data contained on
        its website at any time without notice. However CitSci does not make any
        commitment to update these materials.</p>

      <DeleteAccount></DeleteAccount>

      <h2>Notification of Changes</h2>

      <p>Any changes to this policy will be posted to this website and will become
        effective as of the date of posting with respect to information we then collect
        in the future, but will not be changed with respect to information that we have
        then already collected. We may notify you of such changes are made to our terms
        policy via our email communication channels. CitSci may revise these terms of
        service for its website at any time without notice. By using this website you are
        agreeing to be bound by the then current version of these terms of service.
      </p>

      <br/>

      <p>Please
        <b-link to="contact">contact us</b-link>
        with any
        questions you may have regarding this policy.
      </p>

      <div class="mb-5">&nbsp;</div>

    </div>
  </b-container>
</template>

<script>
import DeleteAccount from "./DeleteAccount.vue";

export default {
  components: {DeleteAccount}
}
</script>
