<template>
  <div class="card-body">
    <div class="row mb-3">
      <h3 class="mb-0 col-md-6">My Assigned Locations</h3>
<!--      <div class="input-group col-md-6">-->
<!--        <b-input-group-prepend>-->
<!--                <span-->
<!--                  class="btn btn-secondary"-->
<!--                  title="Search by location"-->
<!--                >-->
<!--                  <i v-if="!isBusy" class="fas fa-search"></i>-->
<!--                  <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>-->
<!--                </span>-->
<!--        </b-input-group-prepend>-->
<!--        <b-form-input-->
<!--          placeholder="Search by Location Name"-->
<!--          name="search"-->
<!--          type="search"-->
<!--          v-model="searchText"-->
<!--          debounce="1000"-->
<!--        ></b-form-input>-->
<!--        <div class="input-group-append" v-if="searchText">-->
<!--          <b-button @click="searchText = ''"-->
<!--          ><i class="fas fa-times-circle"></i-->
<!--          ></b-button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="row justify-content-between">
      <div class="my-1 col-md-4">
        Showing {{ fromItem }} to {{ toItem }} of
        {{ totalItems }}
        assigned location<span v-if="totalItems === 0 || totalItems > 1">s</span>
      </div>

      <div class="col-md-3 my-1">
        <b-pagination
          v-if="totalItems > 0"
          :total-rows="totalItems"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0 pagination-holder"
          prev-text="Prev"
          next-text="Next"
          align="fill"
        />
      </div>

      <div class="col-md-3 my-1">
        <b-form-group
          label="Per Page"
          label-align="right"
          label-cols-sm="7"
          label-cols-md="8"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            align="right"
          >
          </b-form-select>
        </b-form-group>
      </div>
    </div>

    <b-table show-empty
             stacked="md"
             id="assignedLocationsTable"
             ref="assignedLocationsTable"
             :busy="isBusy"
             :items="locations"
             :fields="tableFields"
             :current-page="currentPage"
             :per-page="perPage"
             sort-icon-left
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :sort-direction="sortDirection"
             :empty-text="emptyText"
    >
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Your Assigned Locations..."/>
      </template>
      <template #cell(project.name)="row">
        <b-link :to="{name:'ProjectAssignedLocations', params:{id:row.item.project.urlField}}" target="_blank" noopener
                noreferrer>
          {{ row.item.project.name }}
        </b-link>
      </template>
      <template #cell(type)="row">
        {{ row.item.datasheet ? 'Datasheet Specific' : 'Project Level' }}
      </template>
      <template #cell(location.name)="row">
        {{ row.item.location.name }} ({{ row.item.location.latitude }}, {{ row.item.location.longitude }})
      </template>
    </b-table>

    <div class="card-footer">
      <div class="row justify-content-between">
        <div class="my-1 col-md-4">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }}
          assigned location<span v-if="totalItems === 0 || totalItems > 1">s</span>
        </div>

        <div class="col-md-3 my-1">
          <b-pagination
            v-if="totalItems > 0"
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </div>

        <div class="col-md-3 my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader.vue";

export default {
  components: {
    VueLoader
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      tableFields: [
        {key: "project.name", label: "Project", sortable: true, sortDirection: "asc",},
        {key: "type", label: "Assignment Type", sortable: false, sortDirection: "asc",},
        {key: "datasheet.name", label: "Datasheet", sortable: true, sortDirection: "asc",},
        {key: "location.name", label: "Location", sortable: true, sortDirection: "asc",},
        {
          key: "updatedAt",
          label: "Date Created",
          sortable: true,
          sortDirection: "desc",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "options",
          label: "Options",
          class: "text-right",
          visible: true
        },
      ],
      emptyText: "No Assigned Locations Yet",
      searchText: "",
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "updatedAt",
      sortDesc: true,
      sortDirection: "desc",
    };
  },

  created() {
    if (this.projectVuex) {
      if (this.$refs.assignedLocationsTable.refresh())
        this.$refs.assignedLocationsTable.refresh()
    }
  },
  watch: {
    projectVuex(newVal) {
      if (newVal) {
        if (this.$refs.assignedLocationsTable.refresh())
          this.$refs.assignedLocationsTable.refresh()
      }
    },
  },
  destroyed() {
  },
  computed: {
    ...mapGetters({
      isLoading: "user/assigned_locations/isLoading",
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    }
  },
  methods: {
    ...mapActions({
      getAssignedLocations: "user/assigned_locations/get",
    }),

    locations(ctx) {
      // Busy state is set
      this.isBusy = true;

      // URL Params are set
      let urlParams = this.urlBuilder(ctx);

      // Assigned locations are fetched
      return this.getAssignedLocations(urlParams).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(locations => {
          return {
            ...locations
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
