<template>
  <div>
      <b-form-group
        id="first-name"
        label-cols-lg="3"
        content-cols-lg="9"
        label="First Name:"
        label-class="font-weight-bold pt-0"
        label-for="input-first-name"
        description="First Name of the Invitee">
        <b-form-input id="input-first-name"
                      name="first-name"
                      v-model="firstName"
                      placeholder="Enter a First Name"
                      v-validate="'required|min:1'"
                      data-vv-as="First Name"
                      :class="{ 'is-invalid': errors.has('first-name') }"
                      aria-describedby="first-name-input-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="first-name-input-live-feedback">
          {{ errors.first("first-name") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="last-name"
        label-cols-lg="3"
        content-cols-lg="9"
        label="Last Name:"
        label-class="font-weight-bold pt-0"
        label-for="input-last-name"
        description="Last Name of the Invitee">
        <b-form-input id="input-last-name"
                      name="last-name"
                      v-model="lastName"
                      placeholder="Enter a Last Name"
                      v-validate="'required|min:2'"
                      data-vv-as="Last Name"
                      :class="{ 'is-invalid': errors.has('last-name') }"
                      aria-describedby="last-name-input-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="last-name-input-live-feedback">
          {{ errors.first("last-name") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="email"
        label-cols-lg="3"
        content-cols-lg="9"
        label="Email:"
        label-class="font-weight-bold pt-0"
        label-for="input-email"
        description="Email Address of the Invitee">
        <b-form-input id="input-email"
                      name="email"
                      v-model="email"
                      placeholder="Enter an Email  (use lowercase)"
                      v-validate="'required|email'"
                      data-vv-as="Email"
                      :class="{ 'is-invalid': errors.has('email') }"
                      aria-describedby="email-input-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="email-input-live-feedback">
          {{ errors.first("email") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="note"
        label-cols-lg="3"
        content-cols-lg="9"
        label="Note To Invitee:"
        label-class="font-weight-bold pt-0"
        label-for="input-note"
        description="A brief message to the invitee">
        <b-form-textarea
          id="input-note"
          name="note"
          v-model="message"
          placeholder="You are invited to this project..."
          v-validate="'required|min:10|max:500'"
          data-vv-as="Note"
          :class="{ 'is-invalid': errors.has('note') }"
          aria-describedby="note-input-live-feedback"
          rows="3"
          max-rows="4"
        ></b-form-textarea>
        <b-form-invalid-feedback id="note-input-live-feedback">
          {{ errors.first("note") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="note"
        label-cols-lg="3"
        content-cols-lg="9"
        label=""
        label-class="font-weight-bold pt-0"
        label-for="btn-invite-single"
        description="">
        <b-btn id="btn-invite-single" variant="tertiary" @click="onInviteClick" :disabled="showMsg"> Send Invite</b-btn>
        <div v-if="isLoading">
          <i class="mt-2 fa fa-spin fa-spinner fa-1x mr-3"></i> Sending Invite...
        </div>
        <b-alert class="mt-2"
                 :show="showMsg"
                 dismissible
                 variant="success">
          <span>Invite sent successfully...</span>
        </b-alert>
        <b-alert class="mt-2"
                 :show="showInviteAlert"
                 dismissible
                 variant="danger">
          An invite already exists for this email.
        </b-alert>
        <b-alert class="mt-2"
                 :show="showMemberAlert"
                 dismissible
                 variant="danger">
          User with this email is already a member.
        </b-alert>
        <b-alert class="mt-2"
                 :show="showInviteError"
                 dismissible
                 variant="success">
          <span>Invite failed... Try again...</span>
        </b-alert>
      </b-form-group>
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'

export default {
  name: "InviteUserSingle",
  components: {},
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      message: null,
      showMsg: false,
      sendData: {},
      showInviteAlert: false,
      showMemberAlert: false,
      showInviteError: false,
      alertTimeout: 10,
    }
  },
  created() {
  },
  watch: {
    memberExists(newVal) {
      if (newVal) {
        this.showMemberAlert = true
      }
    },
    inviteExists(newVal) {
      if (newVal) {
        this.showInviteAlert = true
      }
    },
    inviteError(newVal) {
      if (newVal) {
        this.showInviteError = true
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'project/invites/isLoading',
      project: "project/get/project",
      inviteExists: 'project/invites/inviteExists',
      memberExists: 'project/invites/memberExists',
      inviteError: 'project/invites/error',
    })
  },
  methods: {
    ...mapActions({
      checkInviteExists: 'project/invites/checkInviteExists',
      checkMemberExists: 'project/invites/checkMemberExists',
      inviteProjectMember: 'project/invites/create',
      // inviteProjectMember: 'project/show/inviteProjectMember'
    }),
    onInviteClick() {
      this.showInviteAlert = false
      this.showMemberAlert = false
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendData = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email.toLowerCase().trim(),
            inviteMessage: this.message,
            project: this.project['@id']
          }
          let checks = []
          checks.push(this.checkInviteExists(this.sendData))
          checks.push(this.checkMemberExists(this.sendData))

          Promise.all(checks).then(data => {
            // Check if both checks returned false
            if (data.filter(val => {
              return val.length === 0
            }).length === 2) {
              this.sendInvite()
            }
          })
        }
      })
    },

    sendInvite() {
      console.log('sendInvite', this.sendData)
      this.inviteProjectMember(this.sendData).then((result) => {
        console.log('invite result', result)
        if (result) {
          this.showMsg = true;
          setTimeout(() => {
            this.showMsg = false;
            this.resetFields()
          }, 9000);
        }
      })
    },

    resetFields() {
      this.firstName = null
      this.lastName = null
      this.email = null
      this.message = null
      this.sendData = {}
      this.$validator.reset()
    }
  }
  ,
}
</script>

<style lang="scss" scoped>
</style>
